import React, { useEffect, useContext } from "react";
import { Col } from "./Components";
import FormBuilder from "../../components/FormBuilder/FormBuilder";
import AccessPanel from "../../components/AccessPanel/AccessPanel";
import { AdminContext } from "../../context/AdminContext";

const AdminForm = ({
  updateData,
  data,
  setState,
  documentId,
  entitiesData,
  action,
}) => {
  const { state: adminData } = useContext(AdminContext);

  const isOwn = documentId === adminData?.admin?._id;

  useEffect(() => {
    const getQuery = `
      query {
        admin(_id: "${documentId}") {
          username
          blocked
          email
          access {
            read,
            create,
            delete,
            update
          }
          avatar {
            url,
            _id,
            name,
            ext
          }
          signature {
            name,
            date
          }
          _id
        }
      }
    `;

    const writeQuery = `
      mutation {
        ${action}Admin(
          input: {
            _id: "${documentId || ""}"
            username: "${data.username}"
            blocked: ${data.blocked || false}
            email: "${data.email}"
            access: {
              create: ${JSON.stringify(data.access?.create || [])}
              read: ${JSON.stringify(data.access?.read || [])}
              update: ${JSON.stringify(data.access?.update || [])}
              delete: ${JSON.stringify(data.access?.delete || [])}
            }
            avatar: ${data.avatar?._id ? `"${data.avatar?._id}"` : null}
            password: "${data.password || ""}"
          }
        ) {
          username
          blocked
          email
          access {
            read,
            create,
            delete,
            update
          }
          signature {
            name,
            date
          }
          avatar {
            url,
            _id,
            name,
            ext
          }
          _id
        }
      }
    `;

    setState({ gqlQuery: { get: getQuery, write: writeQuery } });
  }, [setState, documentId, action, data]);

  return (
    <>
      <Col order="first">
        <FormBuilder
          fields={[
            {
              type: "text",
              name: "username",
              label: "Username",
              required: true,
              half: false,
              information: "This is a required field",
            },
            {
              type: "email",
              name: "email",
              label: "Email",
              required: true,
              half: false,
              information: "This is a required field and must be unique",
            },
            {
              type: "password",
              name: "password",
              label: "Password",
              half: false,
              required: action === "edit" ? false : true,
              information:
                action === "edit"
                  ? null
                  : "This is a required field",
            },
          ]}
          updateData={updateData}
          data={data}
        />
      </Col>
      <Col>
        <FormBuilder
          fields={[
            {
              type: "boolean",
              name: "blocked",
              label: "Blocked",
              half: false,
              hide: isOwn,
            },
            {
              type: "image",
              name: "avatar",
              label: "Avatar",
              half: false,
            },
          ]}
          updateData={updateData}
          data={data}
        />
      </Col>
      {!isOwn && (
        <AccessPanel
          entitiesData={entitiesData}
          access={data.access}
          updateData={updateData}
        />
      )}
    </>
  );
};

export default AdminForm;
