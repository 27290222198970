import React from "react";
import { Wrapper, Logo } from "./Components";
import PropTypes from "prop-types";

const AuthWrapper = ({ form, ...props }) => {
  const Form = form;

  return (
    <Wrapper>
      <Logo src={"/images/dolce-travel-logo.svg"} alt="generic logo" />
      <Form {...props} />
    </Wrapper>
  );
};

AuthWrapper.propTypes = {
  form: PropTypes.func,
};

export default AuthWrapper;
