import React from "react";
import { Grid, Month, HiddenInput } from "./Components";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthSelector = ({ updateData, months = [], required }) => {
  const onMonthClick = (isSelected, index) => {
    let newMonths = [...(months || [])];
    if (!isSelected) {
      newMonths.push(index);
      updateData && updateData(newMonths);
    } else {
      const monthIndex = newMonths.findIndex((month) => month === index);
      newMonths.splice(monthIndex, 1);
      updateData && updateData(newMonths);
    }
  };

  return (
    <>
      <HiddenInput
        style={{ width: "1px", opacity: "0", margin: "0", padding: "0" }}
        required={required}
        defaultValue={(months || "").toString()}
      />
      <Grid>
        {MONTHS.map((month, index) => {
          const isSelected = (months || []).includes(index);
          return (
            <Month
              selected={(months || []).includes(index)}
              onClick={() => onMonthClick(isSelected, index)}
              key={`${month}-${index}`}
            >
              {month}
            </Month>
          );
        })}
      </Grid>
    </>
  );
};

export default MonthSelector;
