import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  /* z-index: 1; */
`;

const Input = styled.input`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;
  position: relative;
  /* z-index: 1; */

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    font-size: 14px;
    color: #b2aba9;
    letter-spacing: 0;
    font-size: 13px;
    line-height: 44px;
    height: 44px;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(0, -50%);
  border: none;
  cursor: pointer;
  height: 100%;
  width: 32px;
  font-weight: 600;
  font-size: 13px;
  z-index: 2;
`;

const ListWrapper = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  padding: 8px;
  z-index: 2;
  max-height: 130px;
  overflow: auto;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e8f5ff;
  }
`;

const LoadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NoResult = styled.p`
  padding: 8px;
  text-align: center;
`;

export {
  Input,
  CloseButton,
  Wrapper,
  ListWrapper,
  List,
  ListItem,
  LoadWrapper,
  NoResult,
};
