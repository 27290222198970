import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import { BrowserRouter, Route } from "react-router-dom";
import { AdminProvider } from "./context/AdminContext";
import reportWebVitals from "./reportWebVitals";

try {
  require("dotenv").config();
} catch (e) {
  console.log(e);
}

const root = document.getElementById("root");

ReactDOM.render(
  // <React.StrictMode>
    <AdminProvider>
      <BrowserRouter>
        <Route path={"/"} render={(props) => <App {...props} />} />
      </BrowserRouter>
    </AdminProvider>
  // </React.StrictMode>,
  ,
  root
);

reportWebVitals(console.log);
