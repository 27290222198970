import styled from "styled-components";
import { Link } from "react-router-dom";

const Menu = styled.div`
  background-color: #24272a;
  overflow: auto;
  transition: left 0.5s, width 0.5s;
  display: flex;
  flex-direction: row;
  padding: 0 40px;
`;

const InnerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 48px;

  &::before {
    background-image: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0,
      #fff 5%,
      #fff 90%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    display: block;
    height: 2px;
    opacity: 0.08;
    position: absolute;
    width: calc(100% - 80px);
    max-width: 1200px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 0.2px;
  background-color: ${(props) =>
    props.selected ? "rgba(46, 217, 195, 0.1)" : "transparent"};
  border-bottom: ${(props) => (props.selected ? "2px solid #2ED9C3" : "none")};
  font-size: 12px;
  &:hover {
    background-color: rgba(256, 256, 256, 0.1);
    color: #cb998d;
  }
`;

const Label = styled.p`
  margin: 0;
  font-size: 13.5px;
  line-height: 18px;
  text-align: left;
  font-weight: 400;
  color: ${(props) => (props.selected ? "#DDE2FF" : "#a4a6b3")};
  width: 100%;
  margin-left: 12px;
`;

export { Menu, StyledLink, Label, InnerWrapper };
