import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  height: 150px;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.1s linear;

  &:hover {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%),
      0 2px 4px -1px rgb(0 0 0 / 20%);
    transform: translate(1px);
  }
`;

const Label = styled.span`
  font-weight: 600;
  color: #011f3b;
  margin-top: 12px;
  font-size: 13px;
  text-decoration: none;
`;

const Wrapper = styled.section`
  padding-bottom: 24px;
`;

const FormWrapper = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  width: 100%;
  padding: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(227 233 243) 0px 2px 4px;
  margin-bottom: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  padding: 8px 16px;
  text-decoration: none;
  color: #ffffff;
  background-color: #3751ff;
  font-size: 13px;
  margin-left: auto;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-left: 16px;
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  max-height: 34px;
`;

const Signature = styled.p`
  color: #a4a6b3;
  font-size: 12px;
  font-style: italic;
  & * {
    color: #a4a6b3;
  }
`;

export {
  StyledLink,
  Label,
  Wrapper,
  Header,
  FormWrapper,
  Form,
  Signature,
  Button,
  LoaderWrapper,
};
