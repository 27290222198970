import React from "react";
import pluralize from "pluralize";
import RoutesBuilder from "../../components/RoutesBuilder/RoutesBuilder";
import Listing from "../../containers/Listing/Listing";
import CRUD from "../../containers/CRUD/CRUD";
import { appendKeys } from "../../utils/misc/";

const EntityRoutes = ({ match: { params, path } }) => {
  const ROUTES = [
    {
      path: "/",
      exact: true,
      component: (props) => (
        <Listing {...props} entity={pluralize.singular(params.entity)} />
      ),
    },
    {
      path: "/create",
      exact: true,
      component: (props) => (
        <CRUD
          {...props}
          entity={pluralize.singular(params.entity)}
          action="create"
        />
      ),
    },
    {
      path: "/edit/:id",
      exact: true,
      component: (props) => (
        <CRUD
          {...props}
          entity={pluralize.singular(params.entity)}
          action="edit"
        />
      ),
    },
  ].map(appendKeys);

  return <RoutesBuilder rootPath={path} routesProps={ROUTES} />;
};

export default EntityRoutes;
