import React, { useEffect, useState } from "react";
import Gallery from "../../containers/Gallery/Gallery";
import { GalleryWrapper, ImageSelector, Modal } from "./Components";
import { AddImage } from "../Icons/";

const ImageUploader = ({ initialImage = {}, onImageSelection = () => {} }) => {
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(initialImage || {});
  const [mount, setMounted] = useState(false);

  const onChangeImageSelection = (image) => {
    setSelectedImage(image);
    onImageSelection && onImageSelection(image);
    setShowGalleryModal(false);
  };

  useEffect(() => {
    if (!mount && showGalleryModal) {
      setMounted(true);
    }
  }, [mount, showGalleryModal]);

  return (
    <div>
      <ImageSelector
        onClick={() => setShowGalleryModal((prevState) => !prevState)}
        src={selectedImage.url || ""}
      >
        {!selectedImage.url && (
          <AddImage
            width={"60px"}
            height={"60px"}
            fill={"rgba(255,255,255,0.2)"}
          />
        )}
      </ImageSelector>
      <Modal
        show={showGalleryModal}
        onClick={() => {
          setShowGalleryModal(false);
        }}
      >
        {mount && (
          <GalleryWrapper
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Gallery
              withSelection
              onChangeImageSelection={onChangeImageSelection}
              selectedImageId={selectedImage._id || ""}
            />
          </GalleryWrapper>
        )}
      </Modal>
    </div>
  );
};

export default ImageUploader;
