import React from "react";
import { InputWrapper, Input, IconWrapper } from "./Components";
import { Search } from "../Icons/";
import { useSetState } from "../../hooks";

const EntitySearchBar = ({
  placeholder = "",
  onSearch = () => {},
  defaultValue = "",
}) => {
  const [state, setState] = useSetState({
    value: defaultValue || "",
    timeoutId: null,
  });

  const { value, timeoutId } = state;

  const onChange = ({ target: { value } }) => {
    if (!onSearch && typeof onSearch !== "function") {
      return;
    }

    clearTimeout(timeoutId);

    const ID = setTimeout(() => {
      onSearch(value);
    }, 400);

    setState({
      value: value,
      timeoutId: ID,
    });
  };

  return (
    <InputWrapper>
      <Input placeholder={placeholder} value={value} onChange={onChange} />
      <IconWrapper>
        <Search fill={"#AAB8C6"} width={"24px"} heigth={"24px"} />
      </IconWrapper>
    </InputWrapper>
  );
};

export default EntitySearchBar;
