import React from "react";

const LogoLoader = () => {
  return (
    <img
      src="/images/dolce-travel-logo.svg"
      className="logo-loader animation-loader-pulse"
      width="210px"
      alt="dolce travel logo"
    />
  );
};

export default LogoLoader;
