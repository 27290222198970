import React, { useReducer, useContext, useState, useRef } from "react";
import { setToken } from "../../utils/token";
import { validateEmail, getInputValuesFromForm } from "../../utils/misc";
import request from "../../utils/request";
import { AdminContext } from "../../context/AdminContext";
import Snackbar from "../Snackbar/Snackbar";
import {
  StyledButton,
  CardBody,
  StyledFormControl,
  Label,
  RecaptchaWrapper,
} from "./Components";

import ReCAPTCHA from "react-google-recaptcha";

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const reducer = (state, newState) => ({ ...state, ...newState });

function Login() {
  const recaptchaRef = useRef(null);

  const [state, setState] = useReducer(reducer, {
    error: false,
    errorMessage: "",
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");

  const { dispatch } = useContext(AdminContext);

  function onChangeReCaptcha(value) {
    setRecaptchaToken(value);
  }

  const validate = ({ email, password }) => {
    if (!email) {
      throw new Error("No email provided");
    }
    if (!validateEmail(email)) {
      throw new Error("Email not valid");
    }
    if (!password) {
      throw new Error("Password not provided");
    }
    if (!recaptchaToken) {
      throw new Error("You must select the checkbox");
    }
  };

  const submitAdminLogin = async (e) => {
    try {
      const data = getInputValuesFromForm(e, ["text", "password"]);

      const { email, password } = data;

      validate({ email, password });

      const {
        data: {
          login: { admin, token },
        },
      } = await request(`
        mutation {
          login(
            input: {
              email: "${email}",
              password: "${password}",
              recaptchaToken: "${recaptchaToken}"
            }
          ) {
            admin {
              _id,
              username,
              email,
              avatar {
                url,
                _id,
                ext
              }
              access {
                read,
                create,
                update,
                delete
              } 
            }
            token
          }
        }
      `);

      setToken(token);
      dispatch("login", admin);
    } catch (error) {
      console.log(error);
      const { isAxiosError } = error;
      recaptchaRef.current && recaptchaRef.current.reset();
      let errorMessage = "";

      if (isAxiosError) {
        const res = error.response.data;
        errorMessage =
          (res && res.errors && res.errors[0] && res.errors[0].message) ||
          "Authentication failed";
      } else {
        errorMessage = error.message || "Authentication failed";
      }

      setState({ error: true, errorMessage: errorMessage });
    }
  };

  const { error, errorMessage } = state;

  return (
    <div>
      <CardBody>
        <form onSubmit={submitAdminLogin}>
          <Label htmlFor="login-email-input"> Email </Label>
          <StyledFormControl
            id={"login-email-input"}
            type="text"
            placeholder="Email"
            name="email"
            required
            defaultValue={""}
          />
          <Label htmlFor="login-password-input"> Password </Label>
          <StyledFormControl
            id="login-password-input"
            type="password"
            name="password"
            required
            placeholder="Password"
            defaultValue={""}
          />
          <RecaptchaWrapper>
            <ReCAPTCHA
              sitekey={recaptchaSiteKey}
              onChange={onChangeReCaptcha}
              ref={recaptchaRef}
            />
          </RecaptchaWrapper>
          <StyledButton type="submit">Login</StyledButton>
        </form>
      </CardBody>
      <Snackbar
        open={error}
        message={errorMessage}
        autoHideDuration={3000}
        title={"Error"}
        status={"alert"}
        onClose={() => setState({ error: false, errorMessage: errorMessage })}
      />
    </div>
  );
}

export default Login;
