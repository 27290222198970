import nanoid from "nanoid";
import slugify from "slugify";
const emailRegExp =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const endsWith = (str = "", last = "") => {
  if (typeof str === "string") {
    const length = str.length;
    const lastChar = str[length - 1];
    return lastChar === last;
  }
};

export const startsWith = (str = "", first = "") => {
  if (typeof str === "string") {
    const firstChar = str[0];
    return firstChar === first;
  }
};

export const buildUrl = (paths = []) => {
  const completePath = paths.reduce((result, curr) => {
    let newResult = "";

    if (startsWith(result, "/")) {
      newResult = `${result}`;
    } else {
      newResult = `/${result}`;
    }
    if (endsWith(newResult, "/")) {
      newResult = `${newResult}`;
    } else {
      newResult = `${newResult}/`;
    }

    if (startsWith(curr, "/")) {
      if (endsWith(newResult, "/")) {
        newResult = `${newResult}${curr.substr(1)}`;
      } else {
        newResult = `${newResult}${curr}`;
      }
    } else {
      if (endsWith(newResult, "/")) {
        newResult = `${newResult}${curr}`;
      } else {
        newResult = `${newResult}/${curr}`;
      }
    }

    if (endsWith(newResult, "/")) {
      newResult = `${newResult}`;
    } else {
      newResult = `${newResult}/`;
    }

    return newResult;
  }, "");

  return completePath;
};

export const generateKeys = (numberOfKeys = 1) => {
  const keys = [];
  for (let i = 0; i < numberOfKeys; i++) {
    keys.push(nanoid(12));
  }
  return keys;
};

export const appendKeys = (item) => {
  const [key] = generateKeys(1);
  item.key = key;
  return item;
};

export const normalizeCamel = (str) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/-/g, " ");
};

export const getInputValuesFromForm = (
  event,
  inputTypes,
  options = { skipNames: [] }
) => {
  event.preventDefault();

  const inputArray = Array.from(event.currentTarget.elements);

  let obj = {};
  let allowedInputTypes = inputTypes ? inputTypes : [];

  inputArray.forEach((e) => {
    if (
      (e.tagName === "INPUT" ||
        e.tagName === "TEXTAREA" ||
        e.tagName === "SELECT") &&
      allowedInputTypes.indexOf(e.type) !== -1 &&
      options.skipNames.indexOf(e.name) === -1
    ) {
      obj[e.name] = e.value;
    }
  });

  return obj;
};

export const validateEmail = (email) => {
  const isEmail = emailRegExp.test(email);
  return isEmail;
};

export const getThumbnailUrl = (file) => {
  if (!file) return null;

  const { ext, url, hasThumbnail, key: s3Key } = file;

  if (!ext || !hasThumbnail) return url;

  const pathArray = url.split("/");

  const protocol = pathArray[0];
  const host = pathArray[2];

  const keyWithoutExt = s3Key.split(`.${ext}`).join("");

  const slugifiedKey = slugify(keyWithoutExt, "_").replace(/\+/g, "%20");

  const thumbnailUrl = `${protocol}//${host}/${slugifiedKey}_thumbnail.${ext}`;

  return thumbnailUrl;
};

export const extractErrorMessage = (e) => {
  try {
    let errorMessage = e.message || "Something went wrong";

    const { isAxiosError } = e;

    if (isAxiosError) {
      const res = e.response.data;
      errorMessage =
        (res &&
          res.errors &&
          res.errors[0] &&
          res.errors[0].formattedMessage) ||
        "Something went wrong";
    }

    return errorMessage;
  } catch (e) {
    return "Something went wrong";
  }
};
