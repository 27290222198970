import React from "react";
import { Wrapper, Divider, Title, Header, ContentWrapper } from "./Components";

const ContentPanel = ({ label, children }) => {
  return (
    <Wrapper>
      <Header>
        <Title>{label}</Title>
      </Header>
      <Divider />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

export default ContentPanel;
