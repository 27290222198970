import React, { useMemo } from "react";

import ImageContainer from "../ImageContainer/ImageContainer";
import GalleryPagination from "../GalleryPagination/GalleryPagination";

import {
  Wrapper,
  SelectWrapper,
  ToolBar,
  Skeleton,
  PaginationWrapper,
  InputWrapper,
  Text,
  Input,
} from "./Components";

const GalleryList = ({
  imagesList = [],
  actions,
  loadingImages = true,
  nrOfPages,
  pageIndex,
  onPagination,
  withSelection = false,
  selectedImageId,
}) => {
  const { onSort = () => {}, onSearch = () => {}, ...otherActions } = actions;

  const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((key) => (
    <Skeleton key={key} />
  ));

  const images = useMemo(() => {
    return (imagesList || []).map((image) => (
      <ImageContainer
        key={image._id}
        image={image}
        actions={otherActions}
        withSelection={withSelection}
        selectedImageId={selectedImageId}
      />
    ));
  }, [imagesList, otherActions, withSelection, selectedImageId]);

  return (
    <Wrapper>
      <ToolBar>
        <InputWrapper>
          <Input
            as="input"
            placeholder={"Search for an asset by its name ..."}
            defaultValue={""}
            onChange={onSearch}
          />
        </InputWrapper>
        <SelectWrapper>
          <Input as="select" onChange={onSort} custom>
            <option value={"newest"}>Newest</option>
            <option value={"oldest"}>Oldest</option>
            <option value={"largest"}>Largest</option>
            <option value={"smallest"}>Smallest</option>
          </Input>
        </SelectWrapper>
      </ToolBar>
      {loadingImages ? (
        skeletons
      ) : (imagesList || []).length > 0 ? (
        images
      ) : (
        <Text style={{ textAlign: "center" }}>
          No file has been uploaded yet.
          <br />
          Use the <strong>dropzone</strong> above to upload your first image.
        </Text>
      )}
      {nrOfPages > 0 && (
        <PaginationWrapper>
          {loadingImages ? (
            <Skeleton type={"pagination"} />
          ) : (
            <GalleryPagination
              nrOfPages={nrOfPages}
              onPagination={onPagination}
              pageIndex={pageIndex}
              key={`pagination-${pageIndex}`}
            />
          )}
        </PaginationWrapper>
      )}
    </Wrapper>
  );
};

//TODO: colocar PROPTYPES

export default GalleryList;
