import styled from "styled-components";

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 280px;
  height: 250px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
  position: relative;
`;

const Month = styled.div`
  flex-basis: 33.3333%;
  max-width: 33.333333%;
  text-align: center;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#FFFFFF" : "inherit")};
  background-color: ${(props) => (props.selected ? "#2ED9C3" : "inherit")};
  font-weight: ${(props) => (props.selected ? "600" : "inherit")};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      props.selected ? "rgba(46, 217, 195, 0.8)" : "rgba(46, 217, 195, 0.1)"};
  }
`;

const HiddenInput = styled.input`
  height: 1px;
  width: 1px;
  opacity: 0;
  position: absolute;
  z-index: -1;
  background-color: transparent;
`;

export { Grid, Month, HiddenInput };
