import React from "react";
import PropTypes from "prop-types";

import {
  Backdrop,
  ModalWrapper,
  Title,
  Body,
  Footer,
  Button,
} from "./Components";

const Modal = ({
  open = false,
  title = "",
  body = "",
  onContinue = () => {},
  onCancel = () => {},
}) => {
  return (
    <Backdrop open={open} onClick={onCancel}>
      <ModalWrapper>
        <Title>{title}</Title>
        <Body>{body}</Body>
        <Footer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onContinue}>Ok</Button>
        </Footer>
      </ModalWrapper>
    </Backdrop>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Modal;
