import React from "react";
import { Image } from "../Icons";
import { Img, IconWrapper } from "./Components";
import PropTypes from "prop-types";

const TableImage = ({ url }) => {
  return !url ? (
    <IconWrapper>
      <Image fill="rgb(158, 167, 184)" />
    </IconWrapper>
  ) : (
    <Img src={url} />
  );
};

TableImage.propTypes = {
  url: PropTypes.string,
};

export default TableImage;
