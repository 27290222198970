import React from "react";
import FormBuilder from "../FormBuilder/FormBuilder";
import ExpansionPanel from "../ExpansionPanel/ExpansionPanel";
import ContentPanel from "../ContentPanel/ContentPanel";
import request from "../../utils/request";
import { extractErrorMessage } from "../../utils/misc";
import clone from "lodash/clone";
import { useParams } from "react-router-dom";
import { Circle, Button } from "./Components";
import { useAccess } from "../../hooks";

const TranslationsPanel = ({
  translations = [],
  updateData = () => {},
  setState,
  fields = [],
  type = "",
  parentId = "",
}) => {
  const params = useParams();
  const { canUpdate, canCreate } = useAccess(params?.entity);

  const canDeleteTranslations = params?.id ? canUpdate : canCreate;

  const updateTranslation = (name, value, index) => {
    const newTranslationsArr = clone(translations);
    const translation = newTranslationsArr[index] || {};
    newTranslationsArr[index] = { ...(translation || []), [name]: value };
    updateData("translations", newTranslationsArr);
  };

  const onAddNewTranslation = () => {
    const newTranslations = [...(translations || []), {}];
    updateData("translations", newTranslations);
  };

  const onRemoveTranslation = async (_id, index) => {
    try {
      if (!canDeleteTranslations) return;

      const nrOfTranslations = (translations || []).length;
      const isTranslationSaved = !!_id;

      if (nrOfTranslations <= 1)
        throw new Error("You cannot have 0 translations");

      const proceed = !isTranslationSaved
        ? true
        : window.confirm("Are you sure you want to remove this translation?");

      if (proceed === false) return;

      if (isTranslationSaved) {
        await request(`
        mutation {
          delete${type}Translation (
            input: {
              translationId: "${_id}",
              parentId: "${parentId}"
            }
          ) {
            ok, 
            status,
            message
          }
        }
      `);
      }

      const newTranslations = [...(translations || [])];
      newTranslations.splice(index, 1);
      updateData("translations", newTranslations);
    } catch (e) {
      const errorMessage = extractErrorMessage(e);

      setState({
        submitted: {
          error: true,
          status: true,
          message: errorMessage,
        },
      });
    }
  };

  return (
    <ContentPanel label={"Translations"}>
      {(translations || []).map((translation = {}, index) => {
        const { _id } = translation;
        return (
          <ExpansionPanel
            label={translation?.language?.internalName || "New translation"}
            key={`translations-${index}-${_id}`}
            action={
              canDeleteTranslations
                ? {
                    status: "alert",
                    handler: () => onRemoveTranslation(_id, index),
                    actionLabel: "Delete",
                  }
                : null
            }
          >
            <FormBuilder
              fields={fields}
              data={translation}
              updateData={(name, value) =>
                updateTranslation(name, value, index)
              }
            />
          </ExpansionPanel>
        );
      })}
      <Button type="button" onClick={onAddNewTranslation}>
        <Circle>+</Circle> <span>Add new translation</span>
      </Button>
    </ContentPanel>
  );
};

export default TranslationsPanel;
