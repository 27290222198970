import styled from "styled-components";


const Header = styled.th`
  width: ${(props) => (props.first ? "28%" : "20%")};
  text-align: left;
  padding-left: ${(props) => (props.first ? "8px" : "0")};
  font-weight: 400;
  border-bottom: 1px solid #dfe0eb;
`;

const Row = styled.tr`
  width: 100%;
  height: 60px;
`;

const Cell = styled.td`
  width: ${(props) => (props.first ? "28%" : "20%")};
  padding-left: ${(props) => (props.first ? "8px" : "0")};
  text-align: left;
  border-bottom: 1px solid #dfe0eb;
`;

const Label = styled.label`
  width: 100%;
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;
`;

export { Header, Row, Cell, Label };
