import React, { useEffect } from "react";
import FormBuilder from "../../components/FormBuilder/FormBuilder";
import TranslationsPanel from "../../components/TranslationsPanel/TranslationsPanel";
import ContentPanel from "../../components/ContentPanel/ContentPanel";
import ExpansionPanel from "../../components/ExpansionPanel/ExpansionPanel";
import request from "../../utils/request/";
import { Button, Circle } from "./Components";
import { useAccess } from "../../hooks";

const ItineraryForm = ({ updateData, data, setState, documentId, action }) => {
  useEffect(() => {
    const getQuery = `
      query {
        itinerary(_id: "${documentId}") {
          _id
          internalName,
          duration {
            _id,
            internalName
          }
          days {
            _id
            image {
              url,
              _id
            }
            translations{
              _id
              language {
                _id,
                internalName
              }
              subtitle,
              title,
              description
            }
          }
          signature {
            name,
            date
          }
        }
      }
    `;

    const writeQuery = `
      mutation write ($input: ItineraryInput!) {
        ${action}Itinerary (input: $input) {
          _id
          internalName,
          duration {
            _id,
            internalName
          }
          days {
            _id
            image {
              url,
              _id
            }
            translations {
              _id,
              language {
                internalName,
                _id
              }
              title,
              subtitle,
              description
            }
          }
          signature {
            name,
            date
          }
        }
      }
    
    `;

    setState({ gqlQuery: { get: getQuery, write: writeQuery } });
  }, [setState, action, documentId]);

  const { days = [] } = data;

  const { canUpdate, canCreate } = useAccess("itineraries");

  const canDeleteDays = documentId ? canUpdate : canCreate;

  const onAddNewDay = () => {
    const newDays = [...days, {}];
    updateData("days", newDays);
  };

  const updateItineraryDay = (name, value, index) => {
    const newDays = [...days];
    const newDay = newDays[index] || {};
    newDays[index] = { ...newDay, [name]: value };
    updateData("days", newDays);
  };

  const onRemoveItineraryDay = async (_id, index) => {
    try {
      if (!canDeleteDays) return;

      const nrOfDaysInItinerary = (days || []).length;
      const isDayAlreadySaved = !!_id;

      if (nrOfDaysInItinerary <= 1)
        throw new Error("You cannot have 0 days in an itinerary");

      const proceed = !isDayAlreadySaved
        ? true
        : window.confirm("Are you sure you want to remove this day?");

      if (proceed === false) return;

      if (isDayAlreadySaved) {
        await request(`
        mutation {
          deleteItineraryDay (input: {
            _id: "${_id}"
            index: ${index}
          }) {
            ok, 
            status,
            message
          }
        }
      `);
      }

      const newDays = [...days];
      newDays.splice(index, 1);
      updateData("days", newDays);
    } catch (e) {
      setState({
        submitted: {
          error: true,
          status: true,
          message:
            e.message ||
            "Error when trying to delete this day from the itinerary",
        },
      });
    }
  };

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "internalName",
            type: "text",
            label: "Internal name",
            required: true,
            information:
              "This is a required field and it's only used for internal control.",
            half: true,
          },
          {
            name: "duration",
            type: "relation",
            label: "Trip Duration",
            collection: "durations",
            information: "This is a required field.",
            required: true,
            half: true,
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <ContentPanel label="Itinerary">
        {(days || []).map((day, index) => {
          const { translations = [], _id } = day;
          return (
            <ExpansionPanel
              label={`Day ${index + 1}`}
              key={`day-${_id}-${index}`}
              defaultOpen={false}
              action={
                canDeleteDays
                  ? {
                      status: "alert",
                      actionLabel: "Delete",
                      handler: () => onRemoveItineraryDay(_id, index),
                    }
                  : null
              }
            >
              <FormBuilder
                fields={[
                  {
                    name: "image",
                    type: "image",
                    label: "Image",
                    required: true,
                    half: false,
                  },
                ]}
                data={day}
                updateData={(name, value) =>
                  updateItineraryDay(name, value, index)
                }
              />
              <TranslationsPanel
                translations={translations}
                updateData={(name, value) =>
                  updateItineraryDay(name, value, index)
                }
                parentId={_id}
                setState={setState}
                type="Itinerary"
                fields={[
                  {
                    name: "language",
                    type: "relation",
                    collection: "languages",
                    label: "Language",
                    half: true,
                    information: "This is a required field",
                    required: true,
                  },
                  {
                    name: "title",
                    type: "text",
                    label: "Title",
                    required: true,
                    information: "This is a required field.",
                    half: true,
                  },
                  {
                    name: "subtitle",
                    type: "text",
                    label: "Subtitle",
                    information:
                      "If blank, subtitle will not be shown to public",
                    half: true,
                  },
                  {
                    name: "description",
                    type: "textarea",
                    label: "Description",
                    required: true,
                    information:
                      "This is a required field. Max number of characters: 360",
                    half: true,
                    maxLength: 360,
                  },
                ]}
              />
            </ExpansionPanel>
          );
        })}
        <Button type="button" onClick={onAddNewDay}>
          <Circle>+</Circle> <span>Add new day</span>
        </Button>
      </ContentPanel>
    </>
  );
};

export default ItineraryForm;
