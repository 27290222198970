import React, { useCallback, useEffect } from "react";
import Table from "react-data-table-component";
import Loader from "react-loader-spinner";
import Title from "../../components/Title/Title";
import Modal from "../../components/Modal/Modal";
import Snackbar from "../../components/Snackbar/Snackbar";
import EntitySearchBar from "../../components/EntitySearchBar/EntitySearchBar";
import { buildUrl, extractErrorMessage } from "../../utils/misc";
import request from "../../utils/request";
import { useSetState, useCapitalizedName, useAccess } from "../../hooks/";

import {
  Wrapper,
  TableWrapper,
  LoaderWrapper,
  Header,
  StyledLink,
  DeleteButton,
} from "./Components";

import {
  innerQueryFields,
  tableColumns,
  tableStyles,
} from "../../config/listing";

const extractSort = (columns) => {
  const element = (columns || []).find((element) => element.defaultSort);

  if (!element) return "_id:asc";

  return `${element.id}:${element.sortDirection || "asc"}`;
};

const Listing = ({
  entity,
  location: { pathname },
  history,
  match: { params },
}) => {
  const createUrl = buildUrl([pathname, "create"]);
  const title = useCapitalizedName(entity);
  const queryName = `all${title}`;
  const mutationDeleteName = `delete${title}`;

  const { canRead, canCreate, canDelete } = useAccess(params?.entity);

  const [state, setState] = useSetState({
    items: [],
    count: 0,
    loading: true,
    page: 1,
    limit: 10,
    shouldClearSelectedRows: false,
    rowsToDelete: [],
    itemsPerPage: 10,
    sort: extractSort(tableColumns[title]),
    searchValue: "",
    modal: {
      open: false,
      title: "",
      body: "",
    },
    snackbar: {
      open: false,
      message: "",
    },
  });

  const getData = useCallback(async () => {
    const limit = state.limit;
    const page = state.page;
    const sort = state.sort;
    const search = state.searchValue;

    const query = gqlQuery(title, { page, limit, sort, search });

    const { data } = await request(query);

    return data[queryName];
  }, [
    state.limit,
    state.page,
    state.sort,
    title,
    queryName,
    state.searchValue,
  ]);

  useEffect(() => {
    const queryData = async () => {
      try {
        setState({ loading: true });

        const data = await getData();

        const newState = {
          ...data,
          loading: false,
        };

        setState(newState);
      } catch (e) {
        setState({ loading: false });
        console.log(e);
      }
    };

    canRead && queryData();
  }, [setState, getData, queryName, canRead]);

  const handleRowSelected = (row) => {
    const url = buildUrl([pathname, "edit", row._id]);
    history &&
      history.push({
        pathname: url,
      });
  };

  const handleSelectedRows = (data) => {
    const { selectedRows } = data;

    setState({
      rowsToDelete: selectedRows,
      shouldClearSelectedRows: false,
    });
  };

  const handleModalState = (open) => {
    setState({
      modal: {
        open: open,
        title:
          open === true
            ? "You are about to delete these items."
            : state.modal.title,
        body:
          open === true
            ? "This action is irreversible. Are you sure you want to proceed?"
            : state.modal.body,
      },
    });
  };

  const onChangePaginationParameters = (type, data) => {
    setState({
      [type]: data,
    });
  };

  const onDeleteRows = async () => {
    const ids = (state.rowsToDelete || []).map((row) => row._id);
    const stringfiedArray = JSON.stringify(ids);

    try {
      await request(`
         mutation {
           ${mutationDeleteName}(
             input: ${stringfiedArray}
           ) {
            ok,
            message,
            status
           }
         }
      `);

      const data = await getData();

      const newState = {
        ...data,
        shouldClearSelectedRows: true,
        loading: false,
      };

      setState(newState);
    } catch (e) {
      console.log(e);
      const errorMessage = extractErrorMessage(e);
      setState({
        snackbar: {
          open: true,
          message: errorMessage,
        },
      });
    }
  };

  const handleSort = (column, sortDirection) => {
    const sort = `${column.id}:${sortDirection}`;
    setState({ sort });
  };

  const handleSearch = (value) => {
    setState({ searchValue: value });
  };

  const {
    items = [],
    loading,
    page,
    count,
    shouldClearSelectedRows,
    modal,
    limit,
    sort,
    searchValue,
    snackbar,
  } = state;

  if (!canRead) {
    return null;
  }

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        {canCreate && <StyledLink to={createUrl}>Create {entity}</StyledLink>}
      </Header>
      <TableWrapper>
        <Table
          customStyles={{
            tableWrapper: {
              style: {
                height: items.length > 0 ? "auto" : "400px",
                display: "flex",
                alignItems: items.length > 0 ? "flex-start" : "center",
                background: "#FFFFFF",
              },
            },
            ...tableStyles,
          }}
          columns={tableColumns[title]}
          data={items}
          selectableRows
          clearSelectedRows={shouldClearSelectedRows}
          actions={
            <EntitySearchBar
              placeholder={`Search for ${(title || "").toLowerCase()}....`}
              onSearch={handleSearch}
              defaultValue={searchValue}
            />
          }
          contextActions={
            <div style={{ padding: "10px" }}>
              {canDelete && (
                <DeleteButton
                  onClick={() => {
                    handleModalState(true);
                  }}
                >
                  Delete {title.toLowerCase()}
                </DeleteButton>
              )}
            </div>
          }
          highlightOnHover
          pointerOnHover
          progressPending={loading}
          progressComponent={
            <LoaderWrapper>
              <Loader type="TailSpin" color="#00BFFF" height={60} width={60} />
            </LoaderWrapper>
          }
          onChangeRowsPerPage={(data) =>
            onChangePaginationParameters("limit", data)
          }
          onChangePage={(data) => onChangePaginationParameters("page", data)}
          onSelectedRowsChange={handleSelectedRows}
          onRowClicked={handleRowSelected}
          pagination
          paginationServer
          paginationTotalRows={count}
          paginationPerPage={limit}
          paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
          paginationDefaultPage={page}
          sortServer
          onSort={handleSort}
          defaultSortFieldId={sort.split(":")[0]}
          defaultSortAsc={sort.split(":")[1] === "asc"}
        />
      </TableWrapper>
      <Modal
        open={modal.open}
        onCancel={() => handleModalState(false)}
        onContinue={onDeleteRows}
        title={modal.title}
        body={modal.body}
      />
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        title={"Error"}
        status={"alert"}
        autoHideDuration={3000}
        onClose={() =>
          setState({
            snackbar: {
              open: false,
              message: "",
            },
          })
        }
      />
    </Wrapper>
  );
};

export default Listing;

const innerQuery = (collection) => {
  const fields = innerQueryFields[collection];
  const totalFields = fields.length;

  const query = fields.reduce((result, current, index) => {
    let partialQuery = result + current + (index === totalFields ? "" : ",");
    return partialQuery;
  }, "");

  return query;
};

const gqlQuery = (collection, { page, limit, sort, search }) => `
  query {
    all${collection} (
      input: {
        page: ${page}
        limit: ${limit}
        sort: "${sort}"
        search: "${search}"
      }
    ) {
      items {
        ${innerQuery(collection)}
      }
      count
    }
  }
`;
