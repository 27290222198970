import React from "react";
import BooleanButton from "../BooleanButton/BooleanButton";
import ImageUploder from "../ImageUploader/ImageUploader";
import SingleRelation from "../SingleRelation/SingleRelation";
import MultipleRelations from "../MultipleRelations/MultipleRelations";
import Keywords from "../Keywords/Keywords";
import SlugInput from "../SlugInput/SlugInput";
import MonthSelector from "../MonthSelector/MonthSelector";

import { ElementWrapper, Label, Input, Textarea, Info } from "./Components";
import PropTypes from "prop-types";

const FormBuilder = ({ fields = [], updateData = () => {}, data = {} }) => {
  return fields.map((field, index) => {
    const value = data[field.name];

    let formField = null;

    switch (field.type) {
      case "month-selector": {
        formField = (
          <MonthSelector
            months={value}
            updateData={(months) => updateData(field.name, months)}
            required={field.required}
          />
        );
        break;
      }
      case "slug": {
        formField = (
          <SlugInput
            defaultValue={value}
            id={`${field.name}-${index}`}
            onSlugify={(items) => updateData(field.name, items)}
            collection={field.collection}
            required={field.required}
          />
        );
        break;
      }
      case "relations": {
        formField = (
          <MultipleRelations
            value={value}
            id={`${field.name}-${index}`}
            onChangeSelection={(items) => updateData(field.name, items)}
            collection={field.collection}
            required={field.required}
          />
        );
        break;
      }
      case "relation": {
        formField = (
          <SingleRelation
            value={value}
            id={`${field.name}-${index}`}
            onSelect={(item) => updateData(field.name, item)}
            collection={field.collection}
            required={field.required}
          />
        );
        break;
      }
      case "keywords": {
        formField = (
          <Keywords
            value={value}
            id={`${field.name}-${index}`}
            onChangeSelection={(items) => updateData(field.name, items)}
            collection={field.collection}
            required={field.required}
            label={field.label}
          />
        );
        break;
      }
      case "image": {
        formField = (
          <ImageUploder
            key={value?._id}
            initialImage={value}
            onImageSelection={(image) => updateData(field.name, image)}
          />
        );
        break;
      }
      case "boolean": {
        formField = (
          <BooleanButton
            id={`${field.name}-${index}`}
            value={value === undefined ? false : value}
            onChange={(value) => updateData(field.name, value)}
          />
        );
        break;
      }
      case "textarea": {
        formField = (
          <Textarea
            row={6}
            id={`${field.name}-${index}`}
            type={field.type}
            name={field.name}
            defaultValue={value}
            placeholder={field.label}
            required={field.required}
            maxLength={field.maxLength}
            onChange={({ target: { value } }) => updateData(field.name, value)}
          />
        );
        break;
      }
      case "number": {
        formField = (
          <Input
            id={`${field.name}-${index}`}
            type={field.type}
            name={field.name}
            defaultValue={value}
            placeholder={field.label}
            title={field.title}
            pattern={field.pattern}
            required={field.required}
            disabled={field.disabled}
            min={field.min}
            max={field.max}
            step={field.step}
            onChange={({ target: { value } }) =>
              updateData(field.name, Number(value))
            }
          />
        );
        break;
      }
      default: {
        formField = (
          <Input
            id={`${field.name}-${index}`}
            type={field.type}
            name={field.name}
            defaultValue={value}
            placeholder={field.label}
            title={field.title}
            pattern={field.pattern}
            required={field.required}
            disabled={field.disabled}
            maxLength={field.maxLength}
            onChange={({ target: { value } }) => updateData(field.name, value)}
          />
        );
      }
    }

    return field.hide ? null : (
      <ElementWrapper half={field.half} key={`${field.name}-${index}`}>
        <Label htmlFor={`${field.name}-${index}`}>{field.label}</Label>
        {formField}
        {field.information ? <Info>{field.information}</Info> : null}
      </ElementWrapper>
    );
  });
};

FormBuilder.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf([
        "textarea",
        "boolean",
        "image",
        "relation",
        "relations",
        "text",
        "number",
        "slug",
        "month-view",
        "email",
        "password",
        "month-selector",
        "keywords",
      ]).isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      icon: PropTypes.node,
      information: PropTypes.string,
      title: PropTypes.string,
      half: PropTypes.bool,
      collection: PropTypes.string,
    })
  ).isRequired,
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default FormBuilder;
