import React, { useCallback, useMemo, memo } from "react";
import { useDropzone } from "react-dropzone";
import { styles, messages } from "./config";
import { DropZoneWrapper, Text, Title } from "./Components";
import Loader from "react-loader-spinner";
import { Upload } from "../Icons";
import { useSetState } from "../../hooks/";
import PropTypes from "prop-types";

import request from "../../utils/request";
import io from "socket.io-client";
const apiUrl = process.env.REACT_APP_API_URL;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result.replace(`data:${file.type};base64,`, "").trim());
    };
    reader.onerror = (error) => reject(error);
  });

const uploadImage = async (file) => {
  const base64 = await toBase64(file);

  const {
    data: { upload },
  } = await request(`
    mutation {
      upload (
        input: {
          base64: "${base64}"
          name: "${file.name}"
          mimetype: "${file.type}"
        }
      ){
        ok
        eventId
      }
    }
  `);

  const { eventId } = upload;

  const socket = io(apiUrl, {
    query: {
      roomId: eventId,
    },
  });

  return new Promise((resolve, reject) => {
    socket.on(eventId, (status) => {
      const { ok, file } = status;

      if (ok === true) {
        resolve(file);
      } else {
        reject("Something went wrong");
      }

      socket.disconnect();
    });
  });
};

const FileDropZone = ({ onSuccess = () => {} }) => {
  const [state, setState] = useSetState({
    isLoading: false,
    errorMessage: "",
  });

  const { isLoading, errorMessage } = state;

  const { baseStyle, activeStyle, acceptStyle, rejectStyle } = styles;

  const { defaultMessage, dragActiveMessage, rejectedMessage } = messages;

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      try {
        setState({
          isLoading: true,
        });

        const uploadPromises = acceptedFiles.map((file) => uploadImage(file));

        const filesUploaded = await Promise.all(uploadPromises);

        onSuccess(filesUploaded);

        setState({
          isLoading: false,
        });
      } catch (e) {
        setState({
          isLoading: false,
          errorMessage: "Something went wrong. Please, try again.",
        });

        console.log("e", e);
      }
    },
    [onSuccess, setState]
  );

  const constructErrorMessage = (errors) => {
    try {
      let errorMessage = "";
      const max = Math.min(errors.length, 2);
      for (let i = 0; i < max; i++) {
        errorMessage =
          errorMessage +
          `<b> Error: </b> ${
            errors[i].errors && errors[i].errors[0].message
          } <b> File: </b> ${errors[i].file && errors[i].file.name} <br />`;
      }

      if (errors.length > 2) {
        errorMessage = errorMessage + "and more ...";
      }

      return errorMessage;
    } catch (e) {
      console.log("e", e);
      return "Something wrong happened and some of the images may not have been uploaded";
    }
  };

  const onDropRejected = useCallback(
    (errors) => {
      const errorMessage = constructErrorMessage(errors);
      setState({
        errorMessage: errorMessage,
      });
    },
    [setState]
  );

  const onDragEnter = () => {
    !isLoading &&
      setState({
        errorMessage: "",
      });
  };

  const onClickDropZone = () => {
    !isLoading &&
      setState({
        errorMessage: "",
      });
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onDragEnter,
    accept: "image/*",
    maxFiles: 10,
    enabled: !isLoading,
    maxSize: 2 * 1024 * 1024,
  });

  const style = useMemo(() => {
    const mergedStyles = {
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };

    return mergedStyles;
  }, [
    isDragActive,
    isDragAccept,
    isDragReject,
    activeStyle,
    acceptStyle,
    rejectStyle,
    baseStyle,
  ]);

  return (
    <DropZoneWrapper onClick={onClickDropZone}>
      <Upload />
      <Title>Add Images</Title>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isLoading ? (
          <div>
            <Loader type="ThreeDots" color="#2aace2" />
          </div>
        ) : isDragReject ? (
          <Text
            error={"true"}
            dangerouslySetInnerHTML={{ __html: rejectedMessage }}
            style={{ margin: 0 }}
          />
        ) : isDragActive ? (
          <Text dangerouslySetInnerHTML={{ __html: dragActiveMessage }} />
        ) : (
          <Text dangerouslySetInnerHTML={{ __html: defaultMessage }} />
        )}
      </div>
      <Text error={"true"} dangerouslySetInnerHTML={{ __html: errorMessage }} />
    </DropZoneWrapper>
  );
};

FileDropZone.propTypes = {
  onSuccess: PropTypes.func,
};

export default memo(FileDropZone);
