import styled from "styled-components";

const CardBody = styled.div`
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 25px;
  min-width: 354px;
  max-width: 354px;
  width: 100%;
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: #2ED9C3;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 10px 24px;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    outline: none;
  }
`;

const StyledFormControl = styled.input`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 10px 12px;
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;

  &::placeholder {
    font-size: 14px;
    color: #b2aba9;
    letter-spacing: 0;
    font-size: 13px;
    line-height: 44px;
    height: 44px;
  }
`;

const Label = styled.label`
  width: 100%;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;
`;

const RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 15px;
`;

export { StyledButton, CardBody, StyledFormControl, Label, RecaptchaWrapper };
