import React, { useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useCapitalizedName, useSetState } from "../../hooks/";
import request from "../../utils/request/";
import {
  Wrapper,
  Container,
  InputWrapper,
  ListWrapper,
  List,
  ListItem,
  LoadWrapper,
  NoResult,
  Input,
  Chip,
  RemoveButton,
  HiddenInput,
} from "./Components";

const MultipleRelations = ({
  collection,
  onChangeSelection,
  value,
  ...inputProps
}) => {
  const [state, setState] = useSetState({
    items: null,
    selectedItems: value || [],
    searchValue: value?.internalName || "",
    loading: false,
    setTimeoutId: null,
    listOpen: false,
    focusedChip: null,
    updatedOnMount: false,
  });

  const inputRef = useRef(null);
  const hiddenInputRef = useRef(null);

  const {
    items,
    searchValue,
    selectedItems,
    loading,
    setTimeoutId,
    listOpen,
    focusedChip,
    updatedOnMount,
  } = state;

  const collectionName = useCapitalizedName(collection);
  const queryName = ` all${collectionName}`;

  useEffect(() => {
    if (!updatedOnMount && value !== undefined) {
      setState({
        selectedItems: value,
        updatedOnMount: true,
        searchValue: value?.internalName || "",
      });
    }
  }, [value, updatedOnMount, setState]);

  const focusInput = (which) => {
    switch (which) {
      case "hidden": {
        hiddenInputRef?.current?.focus();
        break;
      }
      default: {
        inputRef?.current?.focus();
        setState({
          focusedChip: null,
        });
      }
    }
  };

  const blurInput = (which) => {
    switch (which) {
      case "hidden": {
        hiddenInputRef?.current?.blur();
        break;
      }
      default: {
        inputRef?.current?.blur();
      }
    }
  };

  const onSelectItem = (item) => {
    const newSelectedItems = [...(selectedItems || [])];
    newSelectedItems.push(item);
    setState({
      selectedItems: newSelectedItems,
      listOpen: false,
      searchValue: "",
    });
    onChangeSelection && onChangeSelection(newSelectedItems);
  };

  const onRemoveItem = (index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems.splice(index, 1);
    setState({
      selectedItems: newSelectedItems,
      focusedChip: null,
    });
    onChangeSelection && onChangeSelection(newSelectedItems);
  };

  const onChange = ({ target: { value } }) => {
    clearTimeout(setTimeoutId);

    setState({
      loading: !!value,
      searchValue: value,
      listOpen: !!value,
      focusedChip: null,
    });

    if (!value) return;

    const ID = setTimeout(async () => {
      try {
        const { data } = await request(`
          query {
            ${queryName}(
              input: {
                page: 1,
                limit: 12,
                search: "${value}"
              }
            ) {
              items {
                _id,
                internalName
              }
            }
          }
        `);

        const { items } = data[queryName.trim()];

        setState({
          items: items,
          loading: false,
        });
      } catch (e) {
        setState({
          items: [],
          loading: false,
        });
      }
    }, 800);

    setState({
      setTimeoutId: ID,
    });
  };

  const onHideList = () => {
    setState({
      listOpen: false,
    });
  };

  const focusChip = (index) => {
    setState({
      focusedChip: index,
    });
    focusInput("hidden");
    blurInput();
  };

  const onKeyDown = ({ key }) => {
    switch (key) {
      case "Backspace": {
        const nrOfSelectedItems = (selectedItems || []).length;
        if (!searchValue && nrOfSelectedItems > 0) {
          if (focusedChip === null) {
            focusChip(nrOfSelectedItems - 1);
          } else if (focusedChip === nrOfSelectedItems - 1) {
            onRemoveItem(nrOfSelectedItems - 1);
          }
        }
        break;
      }
      default: {
        // no-op
      }
    }
  };

  const onBlur = () => {
    setState({
      focusedChip: null,
    });
  };

  return (
    <Wrapper onClick={focusInput}>
      {listOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onClick={onHideList}
        />
      )}
      <Container>
        {(selectedItems || []).map((item, index) => {
          return (
            <Chip
              key={`chip-${item?._id}-${index}`}
              focused={index === focusedChip}
            >
              {item.internalName}
              <RemoveButton onClick={() => onRemoveItem(index)} type="button">
                X
              </RemoveButton>
            </Chip>
          );
        })}
        <HiddenInput
          ref={hiddenInputRef}
          onKeyDown={onKeyDown}
          defaultValue=""
          onChange={focusInput}
          onBlur={onBlur}
        />
        <InputWrapper>
          <Input
            {...inputProps}
            value={searchValue}
            onChange={onChange}
            type="text"
            ref={inputRef}
            autoComplete="off"
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            placeholder={
              (selectedItems || []).length > 0 ? "" : `Search for ${collection}`
            }
          />
        </InputWrapper>
      </Container>
      {listOpen ? (
        <ListWrapper>
          {loading ? (
            <LoadWrapper>
              <Loader
                type="TailSpin"
                width="24px"
                height="24px"
                color="#2aace2"
              />
            </LoadWrapper>
          ) : (
            <div>
              {items.length === 0 ? (
                <NoResult>Couldn't find anything</NoResult>
              ) : (
                <List>
                  {items.map((item) => (
                    <ListItem key={item._id} onClick={() => onSelectItem(item)}>
                      {item.internalName}
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          )}
        </ListWrapper>
      ) : null}
    </Wrapper>
  );
};

export default MultipleRelations;
