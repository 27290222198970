import React from "react";

const Homepage = ({ width = "24px", height = "24px", fill = "#000000" }) => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      fill={fill}
      height={height}
      viewBox="0 0 512 512"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path d="m387.737 72.642c5.522 0 10-4.478 10-10s-4.478-10-10-10h-.057c-5.522 0-9.972 4.478-9.972 10s4.507 10 10.029 10z" />
          <path d="m441.708 72.642c5.522 0 10-4.478 10-10s-4.478-10-10-10h-.057c-5.522 0-9.972 4.478-9.972 10s4.507 10 10.029 10z" />
          <path d="m333.767 72.642c5.522 0 10-4.478 10-10s-4.478-10-10-10h-.057c-5.522 0-9.972 4.478-9.972 10s4.506 10 10.029 10z" />
          <path d="m70.264 72.642h150.262c5.522 0 10-4.478 10-10s-4.478-10-10-10h-150.262c-5.522 0-10 4.478-10 10s4.477 10 10 10z" />
          <path d="m479.919.003h-447.838c-17.689 0-32.081 14.392-32.081 32.081v370.283c0 17.689 14.392 32.081 32.081 32.081h77.594c6.233 9.151 13.37 17.778 21.376 25.784 33.365 33.38 77.736 51.764 124.938 51.764 47.201 0 91.579-18.383 124.958-51.762 8.049-8.049 15.145-16.695 21.313-25.786h77.658c17.689 0 32.081-14.392 32.081-32.081v-370.283c.001-17.689-14.391-32.081-32.08-32.081zm-447.838 20h447.838c6.661 0 12.081 5.42 12.081 12.081v73.224h-472v-73.224c0-6.661 5.42-12.081 12.081-12.081zm447.838 394.446h-65.937c33.089-66.177 22.083-148.968-33.034-204.109-27.844-27.843-63.152-45.229-102.108-50.275-37.727-4.889-76.646 2.659-109.602 21.255-4.81 2.714-6.509 8.812-3.795 13.623s8.814 6.507 13.623 3.795c60.977-34.402 138.178-23.816 187.738 25.743 61.087 61.112 61.087 160.527.001 221.612-29.602 29.602-68.956 45.904-110.815 45.904-41.858 0-81.205-16.302-110.795-45.904-49.527-49.527-60.147-126.686-25.824-187.634 2.709-4.812 1.005-10.91-3.807-13.621-4.812-2.707-10.91-1.006-13.62 3.807-18.552 32.943-26.068 71.846-21.166 109.543 2.584 19.874 8.387 38.795 17.143 56.262h-65.84c-6.661 0-12.081-5.42-12.081-12.081v-277.06h472v277.06c0 6.66-5.42 12.08-12.081 12.08z" />
          <path d="m140.774 343.081c3.906 3.904 10.236 3.904 14.143 0l7.137-7.137v90.433c0 5.522 4.478 10 10 10h167.893c5.522 0 10-4.478 10-10v-90.43l7.137 7.135c1.953 1.952 4.512 2.928 7.07 2.928 2.56 0 5.119-.977 7.072-2.93 3.904-3.906 3.903-10.237-.002-14.143l-108.168-108.14c-3.906-3.904-10.235-3.903-14.142.001l-108.14 108.14c-3.905 3.905-3.905 10.237 0 14.143zm130.298 73.295h-30.145v-42.219h30.145zm-15.086-174.365 73.96 73.941v100.425h-38.874v-52.219c0-5.522-4.478-10-10-10h-50.145c-5.522 0-10 4.478-10 10v52.219h-38.874v-100.433z" />
          <path d="m237.561 321.802h36.851c5.522 0 10-4.478 10-10s-4.478-10-10-10h-36.851c-5.522 0-10 4.478-10 10s4.477 10 10 10z" />
          <path d="m145.166 224.51.028-.028c3.905-3.905 3.891-10.223-.015-14.128s-10.251-3.89-14.156.014c-3.905 3.905-3.905 10.237 0 14.143 1.953 1.952 4.512 2.929 7.071 2.929s5.119-.978 7.072-2.93z" />
        </g>
      </g>
    </svg>
  );
};

export default Homepage;
