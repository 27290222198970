import React, { memo, useCallback, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { AdminContext } from "../../context/AdminContext";
import { Menu, StyledLink, Label, InnerWrapper } from "./Components";
import { buildUrl } from "../../utils/misc";
import { entities } from "../../config/navigation/";

const getSelectedLinkFromList = (list = [], selectedTo) => {
  const index = list.findIndex((item) => {
    return item.to !== "/" && selectedTo.includes(item.to);
  });

  return index;
};

const Navigation = ({ path, pathname }) => {
  const {
    state: { admin },
  } = useContext(AdminContext);

  const selectedData = useMemo(() => {
    let selectedTo = pathname.split("/").filter((part) => part !== "");

    const partNum = selectedTo.length;
    if (partNum <= 2) {
      selectedTo.shift();
    } else if (partNum === 3) {
      selectedTo.shift();
      selectedTo.pop();
    }

    selectedTo = selectedTo.join("/") ? `/${selectedTo.join("")}` : "/";

    const selectedLink =
      selectedTo === "/" ? -1 : getSelectedLinkFromList(entities, selectedTo);

    return { selectedLink };
  }, [pathname]);

  const buildList = useCallback(
    (list) => {
      const buildLink = (link, index) => {
        try {
          const { label, to, icon: Icon } = link;

          const entity = to.replace("/", "");

          const hasAccess =
            admin?.role === "superadmin"
              ? true
              : admin?.access?.read?.includes(entity);

          const url = buildUrl([path, to]);

          const isSelected = index === selectedData.selectedLink;

          if (!hasAccess) {
            return null;
          } else {
            return (
              <StyledLink key={link.key} to={url} selected={isSelected}>
                {Icon && <Icon fill={isSelected ? "#DDE2FF" : "#a4a6b3"} />}
                <Label selected={isSelected}>{label}</Label>
              </StyledLink>
            );
          }
        } catch (e) {
          console.log(e);
          return null;
        }
      };

      return list.map((link, index) => {
        return buildLink(link, index);
      });
    },
    [selectedData.selectedLink, path, admin?.role, admin?.access?.read]
  );

  return (
    <Menu>
      <InnerWrapper>{buildList(entities)}</InnerWrapper>
    </Menu>
  );
};

Navigation.propTypes = {
  path: PropTypes.string,
  pathname: PropTypes.string,
};

export default memo(Navigation);
