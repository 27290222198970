import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import Title from "../../../../components/Title/Title";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import request from "../../../../utils/request/";
import { useSetState } from "../../../../hooks/";
import FormBuilder from "../../../../components/FormBuilder/FormBuilder";
import moment from "moment";
import {
  Wrapper,
  Header,
  FormWrapper,
  Form,
  Signature,
  Button,
  LoaderWrapper,
} from "./Components";

const Homepage = () => {
  const [state, setState] = useSetState({
    data: {},
    loading: true,
    submitted: {
      status: false,
      error: false,
      message: "",
    },
  });

  const { data, loading, submitted } = state;

  useEffect(() => {
    const getHomepageData = async () => {
      try {
        const {
          data: { homepage },
        } = await request(`
          query {
            homepage {
              featuredOffers {
                internalName, 
                _id
              }
              signature {
                name,
                date
              }
            }
          }
        `);

        setState({
          data: homepage,
          loading: false,
        });
      } catch (e) {
        setState({
          data: {},
          loading: false,
          submitted: {
            status: true,
            error: true,
            message: "Something went wrong",
          },
        });
      }
    };

    getHomepageData();
  }, [setState]);

  const updateHomepage = async (e) => {
    try {
      e.preventDefault();

      const inputData = { ...data };

      delete inputData["signature"];

      const {
        data: { editHomepage },
      } = await request(
        `mutation editQuery($input: HomepageInput!) {
            editHomepage(input: $input) {
              featuredOffers {
                internalName, 
                _id
              }
              signature {
                name,
                date
              }
            }
          }
      `,
        { input: inputData }
      );

      setState({
        data: editHomepage,
        loading: false,
        submitted: {
          status: true,
          error: false,
          message: `Homepage saved sucessfully`,
        },
      });
    } catch (e) {
      setState({
        data: {},
        loading: false,
        submitted: {
          status: true,
          error: true,
          message: "Something went wrong",
        },
      });
    }
  };

  const updateData = (name, value) => {
    const newData = { ...data, [name]: value };
    setState({ data: newData });
  };

  const onCloseSnackbar = () => {
    setState({
      submitted: {
        error: false,
        status: false,
        message: state.submitted.message,
      },
    });
  };

  return (
    <Wrapper>
      <Header>
        <div>
          <Title>{`Edit homepage`}</Title>
          {data.signature && (
            <Signature>
              last edited by
              <b> {data?.signature?.name} </b>
              on{" "}
              <b>
                {moment(data?.signature?.date).format("YYYY-MM-DD @ hh:mm:ss")}
              </b>
            </Signature>
          )}
        </div>
        <Button type="submit" form="homepage-form">
          Save Homepage
        </Button>
      </Header>
      {loading && (
        <LoaderWrapper>
          <Loader type="TailSpin" color="#00BFFF" height={60} width={60} />
        </LoaderWrapper>
      )}
      <FormWrapper show={!loading}>
        <Form id="homepage-form" onSubmit={updateHomepage}>
          <FormBuilder
            fields={[
              {
                name: "featuredOffers",
                label: "Featured Offers",
                collection: "programs",
                type: "relations",
              },
            ]}
            data={data}
            updateData={updateData}
          />
        </Form>
      </FormWrapper>
      <Snackbar
        open={submitted.status}
        status={submitted.error ? "alert" : "success"}
        title={submitted.error ? "Error" : "Success"}
        message={submitted.message}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
      />
    </Wrapper>
  );
};

export default Homepage;
