import React from "react";
import TableBadge from "../../components/TableBadge/TableBadge";
import TableTranslations from "../../components/TableTranslations/TableTranslations";
import TableImage from "../../components/TableImage/TableImage";

export const tableStyles = {
  rows: {
    style: {
      minHeight: "50px",
    },
  },
  header: {
    style: {
      borderTopRightRadius: "8px",
      borderTopLeftRadius: "8px",
    },
  },
  headCells: {
    style: {
      "& div": {
        color: "#9FA2B4",
        fontWeight: 600,
        letterSpacing: "0.2px",
        fontSize: "12px",
      },
    },
  },
  cells: {
    style: {
      fontSize: "12px",
      "& > div": {
        color: "#252733",
        fontWeight: 600,
        letterSpacing: "0.2px",
        fontSize: "12px",
      },
    },
  },
  pagination: {
    style: {
      borderBottomRightRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
  },
};

export const innerQueryFields = {
  Admins: ["username", "email", "blocked", "role", "_id"],
  Languages: ["code", "_id"],
  Durations: [
    "_id",
    "active",
    "internalName",
    `translations { language { code } } `,
  ],
  Categories: [
    "_id",
    "active",
    "internalName",
    `translations { language { code } } `,
  ],
  Locations: [
    "_id",
    "active",
    "internalName",
    `translations { language { code } } `,
  ],
  Periods: [
    "_id",
    "active",
    "internalName",
    `translations { language { code } } `,
  ],
  Itineraries: [
    "_id",
    "internalName",
    "duration { internalName, numericalValue }",
  ],
  Programs: [
    "_id",
    "active",
    "internalName",
    "thumbnail { url, _id }",
    `translations { language { code } } `,
  ],
};

export const tableColumns = {
  Admins: [
    {
      name: "Name",
      selector: (row) => row["username"],
      id: "username",
      sortable: true,
      defaultSort: true,
      sortDirection: "desc",
    },
    {
      name: "Email",
      selector: (row) => row["email"],
      id: "email",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row["role"],
      id: "role",
    },
    {
      name: "Blocked",
      selector: (row) => row["blocked"],
      id: "blocked",
      sortable: true,
      cell: (row) =>
        row.blocked ? (
          <TableBadge status="alert">True</TableBadge>
        ) : (
          <TableBadge status="success">False</TableBadge>
        ),
    },
  ],
  Languages: [
    {
      name: "Code",
      selector: (row) => row["code"],
      id: "code",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
  ],
  Locations: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
    {
      name: "Active",
      selector: (row) => row["active"],
      id: "active",
      sortable: true,
      cell: (row) =>
        row.active ? (
          <TableBadge status="success">True</TableBadge>
        ) : (
          <TableBadge status="alert">False</TableBadge>
        ),
    },
  ],
  Categories: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "desc",
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
    {
      name: "Active",
      selector: (row) => row["active"],
      id: "active",
      sortable: true,
      cell: (row) =>
        row.active ? (
          <TableBadge status="success">True</TableBadge>
        ) : (
          <TableBadge status="alert">False</TableBadge>
        ),
    },
  ],
  Periods: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
    {
      name: "Active",
      selector: (row) => row["active"],
      id: "active",
      sortable: true,
      cell: (row) =>
        row.active ? (
          <TableBadge status="success">True</TableBadge>
        ) : (
          <TableBadge status="alert">False</TableBadge>
        ),
    },
  ],
  Durations: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
    {
      name: "Active",
      selector: (row) => row["active"],
      id: "active",
      sortable: true,
      cell: (row) =>
        row.active ? (
          <TableBadge status="success">True</TableBadge>
        ) : (
          <TableBadge status="alert">False</TableBadge>
        ),
    },
  ],
  Itineraries: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Duration name",
      selector: (row) => row["duration"]?.["internalName"],
      id: "duration.internalName",
      sortable: false,
    },
    {
      name: "# of days",
      selector: (row) => row["duration"]?.["numericalValue"],
      id: "duration.numericalValue",
      sortable: false,
    },
  ],
  Programs: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Thumbnail",
      selector: (row) => row["thumbnail"]?.["url"],
      id: "thumbnail",
      sortable: false,
      cell: (row) => <TableImage url={row["thumbnail"]?.["url"]} />,
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
    {
      name: "Active",
      selector: (row) => row["active"],
      id: "active",
      sortable: true,
      cell: (row) =>
        row.active ? (
          <TableBadge status="success">True</TableBadge>
        ) : (
          <TableBadge status="alert">False</TableBadge>
        ),
    },
  ],
};
