import styled from "styled-components";

const ButtonWrapper = styled.button`
  width: 52px;
  height: 32px;
  border-radius: 19px;
  background-color: ${(props) =>
    props.status === true ? "#00d200" : "#E0E0E0"};
  padding: 3px;
  position: relative;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s linear;
  display: flex;
  align-items: center;

  &:active,
  :visited,
  :focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Ellipse = styled.div`
  background: #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 26px;
  position: absolute;
  left: ${(props) => (props.status === true ? "23px" : "3px")};
  top: 3px;
  transition: left 0.2s linear;
`;

const Result = styled.span`
  margin-left: 56px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;

export { ButtonWrapper, Ellipse, Result };
