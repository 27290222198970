import styled from "styled-components";

const Input = styled.input`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &::placeholder {
    font-size: 14px;
    color: #b2aba9;
    letter-spacing: 0;
    font-size: 13px;
    line-height: 44px;
    height: 44px;
  }
`;

export { Input };
