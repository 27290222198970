import React, { useCallback, useEffect, useContext, useMemo } from "react";
import { Prompt } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useCapitalizedName, useSetState, useAccess } from "../../hooks/";
import Title from "../../components/Title/Title";
import Snackbar from "../../components/Snackbar/Snackbar";
import Modal from "../../components/Modal/Modal";
import NotFound from "../../components/NotFound/NotFound";
import { entities } from "../../config/navigation/";
import request from "../../utils/request";
import { generateKeys, extractErrorMessage } from "../../utils/misc";
import { AdminContext } from "../../context/AdminContext";
import moment from "moment";

import {
  FormWrapper,
  Wrapper,
  Header,
  Form,
  Button,
  LoaderWrapper,
  Signature,
} from "./Components";

const entitiesData = [
  ...entities,
  {
    model: "Admin",
    key: generateKeys(1)[0],
  },
];

const entityComponents = entitiesData.reduce((result, entity) => {
  const model = entity.model;
  try {
    if (model) result[model] = require(`../${model}/${model}.jsx`).default;
    return result;
  } catch (e) {
    result[model] = null;
    return result;
  }
}, {});

const CRUD = ({ entity, action, match, history, ...props }) => {
  const entityTitle = useCapitalizedName(entity, false);
  const collectionTitle = useCapitalizedName(entity, true);
  const containerTitle = useCapitalizedName(`${action} ${entity}`, false);
  const { state: adminData, dispatch } = useContext(AdminContext);
  const Entity = useMemo(() => entityComponents[entityTitle], [entityTitle]);

  const documentId = match?.params?.id;
  const collection = match?.params?.entity;
  const editMode = action === "edit";
  const isAdmin = entity === "admin";
  const isOwnAdmin = isAdmin && documentId === adminData?.admin?._id;

  const { canUpdate, canCreate, canDelete, canRead } = useAccess(collection);

  const accessData = useMemo(() => {
    return {
      write: editMode ? isOwnAdmin || canUpdate : canCreate,
      delete: editMode && !isOwnAdmin && canDelete,
    };
  }, [isOwnAdmin, editMode, canUpdate, canCreate, canDelete]);

  const [state, setState] = useSetState({
    data: {},
    loading: editMode ? true : false,
    setTimeoutId: null,
    gqlQuery: {
      get: "",
      write: "",
    },
    fetched: false,
    touched: false,
    submitted: {
      status: false,
      error: false,
      message: "",
    },
    modal: {
      open: false,
      body: "",
      title: "",
    },
  });

  useEffect(() => {
    const getData = async () => {
      const fetched = state.fetched;
      const getQuery = state.gqlQuery.get;

      if (editMode && !fetched && getQuery && (isOwnAdmin || canRead)) {
        try {
          const { data } = await request(getQuery);

          setState({
            data: data[entity] || {},
            fetched: true,
            loading: false,
          });
        } catch (e) {
          console.log(e);
          setState({ fetched: true, loading: false });
        }
      }
    };

    getData();
  }, [
    editMode,
    setState,
    state.fetched,
    state.gqlQuery,
    entity,
    canRead,
    isOwnAdmin,
  ]);

  useEffect(() => {
    return () => clearTimeout(state.setTimeoutId);
  }, [state.setTimeoutId]);

  const updateData = useCallback(
    (field, value) => {
      let newData = { ...state.data, [field]: value };
      setState({ data: newData, touched: true });
    },
    [setState, state.data]
  );

  const onSave = async (e) => {
    try {
      e.preventDefault();

      setState({
        loading: true,
      });

      const writeQuery = state.gqlQuery.write;
      const dataToSave = { ...state.data };
      delete dataToSave["signature"];

      const { data } = await request(writeQuery, { input: dataToSave });

      setState({
        loading: false,
        touched: false,
        submitted: {
          status: true,
          error: false,
          message: `${entity} ${editMode ? "edited" : "created"} sucessfully`,
        },
      });

      const entityData = data[`${action}${entityTitle}`];

      if (editMode && !isOwnAdmin) {
        return setState({ data: entityData });
      }

      if (!editMode) {
        const ID = setTimeout(() => {
          history.push({
            pathname: `/dashboard/${collection}/edit/${entityData._id}`,
          });
        }, 1000);

        return setState({ setTimeoutId: ID });
      }

      if (isOwnAdmin) {
        const ID = setTimeout(() => {
          dispatch("write", entityData);
        }, 1000);

        return setState({ setTimeoutId: ID });
      }
    } catch (e) {
      console.log("e", e);
      const message = extractErrorMessage(e);
      setState({
        loading: false,
        submitted: {
          status: true,
          error: true,
          message: message,
        },
      });
    }
  };

  const onDelete = async () => {
    try {
      await request(`
        mutation {
          delete${collectionTitle} (
            input: ${JSON.stringify([documentId])}
          ) {
            ok,
            message,
            status
          }
        }
      `);

      history.push({
        pathname: `/dashboard/${collection}`,
      });
    } catch (e) {
      console.log("e", e);
      const message = extractErrorMessage(e);
      setState({
        submitted: {
          status: true,
          error: true,
          message: message,
        },
      });
    }
  };

  const onOpenModal = () => {
    setState({
      modal: {
        open: true,
        title: `You are about to delete this ${entity}`,
        body: "This action is irreversible. Are you sure you want to proceed?",
      },
    });
  };

  const onCloseModal = () => {
    setState({
      modal: {
        open: false,
        title: state.modal.title,
        body: state.modal.body,
      },
    });
  };

  const onCloseSnackbar = () => {
    setState({
      submitted: {
        error: false,
        status: false,
        message: state.submitted.message,
      },
    });
  };

  const { data, submitted, loading, modal, touched } = state;

  if (!canRead && !isOwnAdmin) {
    return null;
  }

  if (Entity) {
    return (
      <>
        <Prompt
          when={touched}
          message="You are about to leave this form. Everything that hasn't been saved will be lost. Are you sure you want to proceed?"
        />
        <Wrapper>
          <Header>
            <div>
              <Title>{containerTitle}</Title>
              {editMode && !isOwnAdmin && (
                <Signature>
                  last edited by
                  <b> {data?.signature?.name} </b>
                  on{" "}
                  <b>
                    {moment(data?.signature?.date).format(
                      "YYYY-MM-DD @ hh:mm:ss"
                    )}
                  </b>
                </Signature>
              )}
            </div>
            <div>
              {accessData.delete && (
                <Button
                  action="delete"
                  onClick={onOpenModal}
                  disabled={loading}
                >
                  {`${"Delete"} ${entity}`}
                </Button>
              )}
              {accessData.write && (
                <Button
                  action="save"
                  type="submit"
                  form="entity-form"
                  disabled={loading}
                >
                  {`${editMode ? "Save" : "Create"} ${entity}`}
                </Button>
              )}
            </div>
          </Header>
          {loading && (
            <LoaderWrapper>
              <Loader type="TailSpin" color="#00BFFF" height={60} width={60} />
            </LoaderWrapper>
          )}
          <FormWrapper show={!loading}>
            <Form onSubmit={onSave} id="entity-form">
              <Entity
                entity={entity}
                entitiesData={entitiesData}
                updateData={updateData}
                setState={setState}
                data={data}
                action={action}
                documentId={documentId}
                {...props}
              />
            </Form>
          </FormWrapper>
          <Modal
            open={modal.open}
            body={modal.body}
            title={modal.title}
            onContinue={onDelete}
            onCancel={onCloseModal}
          />
          <Snackbar
            open={submitted.status}
            status={submitted.error ? "alert" : "success"}
            title={submitted.error ? "Error" : "Success"}
            message={submitted.message}
            autoHideDuration={3000}
            onClose={onCloseSnackbar}
          />
        </Wrapper>
      </>
    );
  } else {
    return <NotFound />;
  }
};

export default CRUD;
