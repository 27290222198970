import React from "react";
import { ButtonWrapper, Ellipse, Result } from "./Components";
import PropTypes from "prop-types";

const BooleanButton = ({
  value = false,
  onChange = () => {},
  disabled = false,
}) => {
  return (
    <ButtonWrapper
      disabled={disabled}
      status={value}
      type="button"
      onClick={() => !disabled && onChange && onChange(!value)}
    >
      <Ellipse status={value} />
      <Result>{value ? "TRUE" : "FALSE"}</Result>
    </ButtonWrapper>
  );
};

BooleanButton.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BooleanButton;
