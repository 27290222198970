import React from "react";
import { pages } from "../../config/navigation/";
import { buildUrl } from "../../utils/misc/";
import NotFound from "../../components/NotFound/NotFound";
import { StyledLink, Label } from "./Components";

const pagesComponents = pages.reduce((result, entity) => {
  const component = entity.component;
  const slug = entity.to.split("/").join("");
  const label = entity.label;
  try {
    if (component)
      result[slug] = {
        component: require(`./pages/${component}/${component}.jsx`).default,
        label: label,
      };
    return result;
  } catch (e) {
    result[slug] = null;
    return result;
  }
}, {});

const CMS = ({ match, location: { pathname } }) => {
  const { params } = match;

  const contentToRender = params["page"] || "list";

  const Page = pagesComponents[contentToRender]?.component;

  switch (contentToRender) {
    case "list": {
      return pages.map((page) => {
        const { label, to, icon: Icon, key } = page;
        const href = buildUrl([pathname, to]);
        return (
          <StyledLink to={href} key={key}>
            <Icon fill="#5E81F4" />
            <Label>{label}</Label>
          </StyledLink>
        );
      });
    }
    default: {
      return Page ? <Page /> : <NotFound />;
    }
  }
};

export default CMS;
