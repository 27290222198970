import React, { useEffect } from "react";
import FormBuilder from "../../components/FormBuilder/FormBuilder";

const LanguageForm = ({ setState, action, documentId, data, updateData }) => {
  useEffect(() => {
    const getQuery = `
      query {
        language(_id: "${documentId}") {
          internalName
          code
          signature {
            name,
            date
          }
          _id
        }
      }
    `;

    const writeQuery = `
      mutation {
        ${action}Language(
          input: {
            _id: "${documentId || ""}"
            code: "${data.code}"
            internalName: "${data.internalName}"
          }
        ) {
          internalName
          code
          signature {
            name,
            date
          }
          _id
        }
      }
    `;

    setState({ gqlQuery: { get: getQuery, write: writeQuery } });
  }, [setState, documentId, action, data]);

  return (
    <FormBuilder
      fields={[
        {
          name: "internalName",
          label: "Internal name",
          half: true,
          type: "text",
          required: true,
          information:
            "This is a required field and it's only used for internal control..",
        },
        {
          name: "code",
          label: "Code",
          half: true,
          type: "text",
          pattern: "[a-z]{1,8}(-[a-zA-Z0-9]{1,8})*",
          title: "Must follow ISO language code. Ex: pt-BR, es, en-GB",
          required: true,
          information:
            "This is a required field and must be unique. Must follow ISO language code.",
        },
      ]}
      data={data}
      updateData={updateData}
    />
  );
};

export default LanguageForm;
