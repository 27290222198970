import styled from "styled-components";

const Wrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
`;

const InnerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 40px;
`;

const MenuWrapper = styled.div`
  position: relative;
`;

const PageWrapper = styled.div`
  width: 100%;
  padding: 32px 24px;
  height: 100%;
  background-color: #f7faff;
  overflow-y: auto;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  height: calc(100vh - 122px);
  position: relative;
  z-index: 3;
`;
export { Wrapper, PageWrapper, MenuWrapper, Container, InnerWrapper };
