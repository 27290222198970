import {
  ShutterSpeed,
  Language,
  Directions,
  Kitesurfing,
  Explore,
  Event,
  Compass,
  Gallery,
  WYSIWYG,
  Homepage,
} from "../../components/Icons";

import { appendKeys } from "../../utils/misc";

export const entities = [
  {
    to: "/programs",
    label: "Programs",
    icon: Directions,
    model: "Program",
  },
  {
    to: "/itineraries",
    label: "Itineraries",
    icon: Compass,
    model: "Itinerary",
  },
  {
    to: "/categories",
    label: "Categories",
    icon: Kitesurfing,
    model: "Category",
  },
  {
    to: "/locations",
    label: "Locations",
    icon: Explore,
    model: "Location",
  },
  {
    to: "/periods",
    label: "Periods",
    icon: Event,
    model: "Period",
  },
  {
    to: "/durations",
    label: "Durations",
    icon: ShutterSpeed,
    model: "Duration",
  },
  {
    to: "/languages",
    label: "Languages",
    icon: Language,
    model: "Language",
  },
].map(appendKeys);

export const services = [
  {
    to: "/gallery",
    label: "Upload Images",
    icon: Gallery,
  },
  {
    to: "/static-pages",
    label: "Content Management",
    icon: WYSIWYG,
  },
].map(appendKeys);

export const pages = [
  {
    to: "/homepage",
    label: "Homepage",
    icon: Homepage,
    component: "Homepage",
  },
].map(appendKeys);
