import styled from "styled-components";

const ImageSelector = styled.div`
  width: 100%;
  max-width: 420px;
  height: 180px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgb(51, 55, 64);
  background-image: ${(props) =>
    props.src !== "" ? `url('${props.src}')` : "none"};
  border: 12px solid rgb(51, 55, 64);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 80%;
  cursor: pointer;
`;

const GalleryWrapper = styled.div`
  padding: 36px;
  background-color: #f7faff;
  display: flex;
  margin: 0 auto;
  max-width: 1140px;
  z-index: 6;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.show ? "block" : "none")};
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.16);
  padding: 48px;
  z-index: 5;
`;

export { GalleryWrapper, ImageSelector, Modal };
