import React from "react";
import RoutesBuilder from "../RoutesBuilder/RoutesBuilder";
import { appendKeys } from "../../utils/misc/";
import CMS from "../../containers/CMS/CMS";

const ROUTES = [
  {
    path: "/",
    exact: true,
    component: CMS,
  },
  {
    path: "/:page",
    exact: true,
    component: CMS,
  },
].map(appendKeys);

const PagesRoute = ({ match: { path } }) => {
  return <RoutesBuilder rootPath={path} routesProps={ROUTES} />;
};

export default PagesRoute;
