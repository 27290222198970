import React, { useEffect } from "react";
import FormBuilder from "../../components/FormBuilder/FormBuilder";
import TranslationsPanel from "../../components/TranslationsPanel/TranslationsPanel";

const CategoryForm = ({ updateData, data, setState, documentId, action }) => {
  useEffect(() => {
    const getQuery = `
      query {
        duration(_id: "${documentId}") {
          _id
          internalName,
          numericalValue,
          active,
          translations {
             _id,
             name,
             language {
               _id,
               internalName
             }
          }
          signature {
            name,
            date
          }
        }
      }
    `;

    const writeQuery = `
      mutation write ($input: DurationInput!) {
        ${action}Duration (input: $input) {
          _id
          internalName,
          active,
          numericalValue,
          translations {
             _id,
             name,
             language {
              _id,
              internalName
            }
          }
          signature {
            name,
            date
          }
        }
      }
    
    `;

    setState({ gqlQuery: { get: getQuery, write: writeQuery } });
  }, [setState, action, documentId]);

  const { translations = [], _id } = data;

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "active",
            type: "boolean",
            label: "Active",
            information:
              "Decide if the duration is ready to be shown to public.",
            half: false,
          },
          {
            name: "internalName",
            type: "text",
            label: "Internal name",
            required: true,
            information:
              "This is a required field and it's only used for internal control.",
            half: true,
          },
          {
            name: "numericalValue",
            type: "number",
            label: "Numerical value in days",
            required: true,
            information: "This is a required field.",
            half: true,
            min: 1,
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        translations={translations}
        updateData={updateData}
        setState={setState}
        type="Duration"
        parentId={_id}
        fields={[
          {
            name: "language",
            type: "relation",
            collection: "languages",
            label: "Language",
            half: true,
            information: "This is a required field",
            required: true,
          },
          {
            name: "name",
            type: "text",
            label: "Duration name",
            required: true,
            information: "This is a required field.",
            half: true,
          },
        ]}
      />
    </>
  );
};

export default CategoryForm;
