import React, { useEffect } from "react";
import request from "../../utils/request/";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";
import { useCapitalizedName, useSetState } from "../../hooks/";

import {
  Input,
  CloseButton,
  Wrapper,
  ListWrapper,
  List,
  ListItem,
  LoadWrapper,
  NoResult,
} from "./Components";

const SingleRelation = ({ collection, onSelect, value, ...inputProps }) => {
  const [state, setState] = useSetState({
    items: null,
    selectedItem: value || null,
    searchValue: value?.internalName || "",
    loading: false,
    setTimeoutId: null,
    updatedOnMount: false,
  });

  const {
    items,
    searchValue,
    selectedItem,
    loading,
    setTimeoutId,
    updatedOnMount,
  } = state;

  useEffect(() => {
    if (!updatedOnMount && value !== undefined) {
      setState({
        selectedItem: value,
        updatedOnMount: true,
        searchValue: value?.internalName,
      });
    }
  }, [value, updatedOnMount, setState]);

  const collectionName = useCapitalizedName(collection);
  const queryName = ` all${collectionName}`;

  const onChange = ({ target: { value } }) => {
    clearTimeout(setTimeoutId);

    setState({
      loading: !!value,
      searchValue: value,
    });

    if (!value) return;

    const ID = setTimeout(async () => {
      try {
        const { data } = await request(`
          query {
            ${queryName}(
              input: {
                page: 1,
                limit: 12,
                search: "${value}"
              }
            ) {
              items {
                _id,
                internalName
              }
            }
          }
        `);

        const { items } = data[queryName.trim()];

        setState({
          items: items,
          loading: false,
        });
      } catch (e) {
        setState({
          items: [],
          loading: false,
        });
      }
    }, 800);

    setState({
      setTimeoutId: ID,
    });
  };

  const onSelectItem = (item) => {
    setState({
      selectedItem: item,
      searchValue: item?.internalName || "",
    });
    onSelect && onSelect(item);
  };

  const hideDropDown = () => {
    if (!selectedItem) {
      setState({
        searchValue: "",
      });
    }
  };

  return (
    <Wrapper>
      {searchValue && !selectedItem && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2
          }}
          onClick={hideDropDown}
        />
      )}
      <Input
        {...inputProps}
        value={searchValue}
        onChange={onChange}
        placeholder={`Search for ${collection}`}
        type={"text"}
        disabled={!!selectedItem}
        autoComplete="off"
      />
      {selectedItem && (
        <CloseButton onClick={() => onSelectItem(null)}>x</CloseButton>
      )}
      {!!searchValue && !selectedItem ? (
        <ListWrapper>
          {loading ? (
            <LoadWrapper>
              <Loader
                type="TailSpin"
                width="24px"
                height="24px"
                color="#2aace2"
              />
            </LoadWrapper>
          ) : (
            <div>
              {items.length === 0 ? (
                <NoResult>Couldn't find anything</NoResult>
              ) : (
                <List>
                  {items.map((item) => (
                    <ListItem key={item._id} onClick={() => onSelectItem(item)}>
                      {item.internalName}
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          )}
        </ListWrapper>
      ) : null}
    </Wrapper>
  );
};

SingleRelation.propTypes = {
  collection: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    internalName: PropTypes.string.isRequired,
  }),
};

export default SingleRelation;
