import React, { useReducer, useContext, useMemo } from "react";
import Snackbar from "../Snackbar/Snackbar";
import PasswordStrength from "../PasswordStrength/PasswordStrength";
import { setToken } from "../../utils/token";
import { validateEmail, getInputValuesFromForm } from "../../utils/misc";
import request from "../../utils/request/";
import {
  StyledButton,
  CardBody,
  StyledFormControl,
  Label,
  Title,
  P,
} from "./Components";
import { AdminContext } from "../../context/AdminContext";

const reducer = (state, newState) => ({ ...state, ...newState });

const calculatePasswordStrength = (password) => {
  let score = 0;

  if (password.length >= 8) {
    score++;
  } else {
    return score;
  }

  if (/\d/.test(password)) {
    score++;
  }

  if (/[A-Z]/.test(password)) {
    score++;
  }

  if (/\W/.test(password)) {
    score++;
  }

  return score;
};

function Signup({ history }) {
  const [state, setState] = useReducer(reducer, {
    error: false,
    errorMessage: "",
    password: "",
  });

  const { dispatch } = useContext(AdminContext);

  const validate = ({ email, password, username }) => {
    if (!username) {
      throw new Error("No username provided");
    }
    if (!email) {
      throw new Error("No email provided");
    }
    if (!validateEmail(email)) {
      throw new Error("Email not valid");
    }
    if (!password) {
      throw new Error("Password not provided");
    }

    if (password.length < 8) {
      throw new Error("Password must have at least 8 characters");
    }
  };

  const submitAdminSignup = async (e) => {
    try {
      const data = getInputValuesFromForm(e, ["text", "email", "password"]);

      const { email, password, username } = data;

      validate({ email, password, username });

      const {
        data: {
          signup: { token, admin },
        },
      } = await request(`
        mutation { 
          signup (
            input:{
              email: "${email}", 
              password: "${password}", 
              username: "${username}"
            }
          ) {
            token,
            admin {
              username,
              _id,
              email,
              access {
                read,
                create,
                update,
                delete
              } 
            }
          }
        }`);


      setToken(token);
      dispatch("login", admin);
      history.push("/dashboard");
    } catch (error) {
      console.log(error);
      const { isAxiosError } = error;
      const message = isAxiosError ? "Authentication failed" : error.message;
      setState({ error: true, errorMessage: message });
    }
  };

  const { error, errorMessage, password } = state;

  const passStrength = useMemo(() => calculatePasswordStrength(password), [
    password,
  ]);

  return (
    <>
      <CardBody>
        <Title> Create a super admin </Title>
        <P>
          A super admin can be only created once and has full access to all
          resources. It cannot be deleted and is the only one that can create
          and delete other admins.
        </P>
      </CardBody>
      <CardBody>
        <form onSubmit={submitAdminSignup}>
          <Label> Username: </Label>
          <StyledFormControl
            type="text"
            placeholder="Username"
            name="username"
            required
            defaultValue={""}
          />
          <Label> Email: </Label>
          <StyledFormControl
            type="email"
            placeholder="Valid email"
            name="email"
            required
            defaultValue={""}
          />
          <Label> Password: </Label>
          <StyledFormControl
            type="password"
            name="password"
            required
            placeholder="At least 8 characters"
            onChange={({ target: { value } }) => {
              setState({ password: value });
            }}
            value={state.password}
          />
          <PasswordStrength strength={passStrength} />
          <StyledButton type="submit">Register</StyledButton>
        </form>
      </CardBody>
      <Snackbar
        open={error}
        title={"Error"}
        message={errorMessage}
        autoHideDuration={3000}
        status={"alert"}
        onClose={() => setState({ error: false, errorMessage: errorMessage })}
      />
    </>
  );
}

export default Signup;
