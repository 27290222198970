import React, { useEffect, useState, memo, useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import request from "./utils/request";
import { isTokenInvalid, deleteToken } from "./utils/token/";

// Components
import RedirectWhen from "./components/RedirectWhen/RedirectWhen";
import NotFound from "./components/NotFound/NotFound";

// Pages
import LoginPage from "./pages/LoginPage/LoginPage";
import SignupPage from "./pages/SignupPage/SignupPage";
import Backoffice from "./pages/Backoffice/Backoffice";

import { AdminContext } from "./context/AdminContext";

const session = async () => {
  const {
    data: {
      me: { admin },
    },
  } = await request(`
      query { 
        me {
          admin {
            username, 
            email,
            _id,
            avatar {
              url,
              name,
              _id
            }
            access {
              read,
              create,
              update,
              delete
            }              
          }
          token
        }
      }
    `);

  return admin;
};

const Website = ({ location: { pathname }, history }) => {
  const {
    state: { isAuth },
    dispatch,
  } = useContext(AdminContext);

  const handleOnIdle = () => {
    console.log("user is idle");
  };

  const handleOnActive = async () => {
    try {
      if (isAuth) {
        await session();
      }
    } catch (e) {
      dispatch("logout");
      // deleteToken();
    }
    reset();
  };

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 1,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  const [goToSignup, setGoToSignup] = useState(undefined);

  let securePath = pathname.includes("dashboard") ? pathname : "/dashboard";

  useEffect(() => {
    const loginAdmin = async () => {
      try {
        const admin = await session();
        dispatch("login", admin);
      } catch (error) {
        dispatch("logout");
        // deleteToken();
        console.log("[Website] Error:", error);
      }
    };

    const isThereAdmin = async () => {
      try {
        const { data } = await request(`
          query { 
            initialized 
          }
        `);

        return data.initialized;
      } catch (e) {
        return true;
      }
    };
    const handleAdminOnboarding = async () => {
      const adminAlreadyCreated = await isThereAdmin();

      if (adminAlreadyCreated === true) {
        setGoToSignup(false);
        if (isTokenInvalid()) {
          dispatch("logout");
          deleteToken();
        } else {
          loginAdmin();
        }
      } else {
        setGoToSignup(true);
        history.push("/signup");
      }
    };
    handleAdminOnboarding();
    //eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={"/"}
        render={(props) => {
          return (
            <RedirectWhen
              {...props}
              when={isAuth}
              fallBack={LoginPage}
              to={securePath}
            />
          );
        }}
      />
      <Route
        exact
        path={"/signup"}
        render={(props) => {
          return (
            <RedirectWhen
              {...props}
              when={goToSignup === undefined ? undefined : !goToSignup}
              fallBack={SignupPage}
              to={securePath}
            />
          );
        }}
      />
      <Route
        path={"/dashboard"}
        render={(props) => {
          return (
            <RedirectWhen
              {...props}
              when={isAuth === undefined ? undefined : !isAuth}
              fallBack={Backoffice}
              to={"/"}
            />
          );
        }}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default memo(Website);
