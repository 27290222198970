import React, { useRef, useEffect } from "react";
import { useSetState } from "../../hooks";
import {
  Wrapper,
  Container,
  InputWrapper,
  Input,
  Chip,
  RemoveButton,
  HiddenInput,
} from "./Components";

const Keywords = ({
  collection,
  onChangeSelection,
  label,
  value,
  ...inputProps
}) => {
  const [state, setState] = useSetState({
    items: value || [],
    writtenValue: value?.internalName || "",
    loading: false,
    focusedChip: null,
    updatedOnMount: false,
  });

  const inputRef = useRef(null);
  const hiddenInputRef = useRef(null);

  const { writtenValue, items, focusedChip, updatedOnMount } = state;

  useEffect(() => {
    if (!updatedOnMount && value !== undefined) {
      setState({
        items: value,
        updatedOnMount: true,
        writtenValue: value?.internalName || "",
      });
    }
  }, [value, updatedOnMount, setState]);

  const focusInput = (which) => {
    switch (which) {
      case "hidden": {
        hiddenInputRef?.current?.focus();
        break;
      }
      default: {
        inputRef?.current?.focus();
        setState({
          focusedChip: null,
        });
      }
    }
  };

  const blurInput = (which) => {
    switch (which) {
      case "hidden": {
        hiddenInputRef?.current?.blur();
        break;
      }
      default: {
        inputRef?.current?.blur();
      }
    }
  };

  const createKeyWord = async () => {
    if (writtenValue) {
      const newItems = [...(items || [])];
      newItems.push(writtenValue);
      setState({
        items: newItems,
        writtenValue: "",
      });
      onChangeSelection && onChangeSelection(newItems);
    }
  };

  const onRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setState({
      items: newItems,
      focusedChip: null,
    });
    onChangeSelection && onChangeSelection(newItems);
  };

  const onChange = ({ target: { value } }) => {
    setState({
      writtenValue: value,
      focusedChip: null,
    });
  };

  const focusChip = (index) => {
    setState({
      focusedChip: index,
    });
    focusInput("hidden");
    blurInput();
  };

  const onKeyDown = (e) => {
    switch (e?.key) {
      case "Backspace": {
        const nrOfItems = (items || []).length;
        if (!writtenValue && nrOfItems > 0) {
          if (focusedChip === null) {
            focusChip(nrOfItems - 1);
          } else if (focusedChip === nrOfItems - 1) {
            onRemoveItem(nrOfItems - 1);
          }
        }
        break;
      }
      case "Enter": {
        e.preventDefault();
        createKeyWord();
        break;
      }
      default: {
        // no-op
      }
    }
  };

  const onBlur = () => {
    setState({
      focusedChip: null,
    });
  };

  return (
    <Wrapper onClick={focusInput}>
      <Container>
        {(items || []).map((item, index) => {
          return (
            <Chip key={`chip-${item}-${index}`} focused={index === focusedChip}>
              {item}
              <RemoveButton onClick={() => onRemoveItem(index)} type="button">
                X
              </RemoveButton>
            </Chip>
          );
        })}
        <HiddenInput
          ref={hiddenInputRef}
          onKeyDown={onKeyDown}
          defaultValue=""
          onChange={focusInput}
          onBlur={onBlur}
        />
        <InputWrapper>
          <Input
            {...inputProps}
            value={writtenValue}
            onChange={onChange}
            type="text"
            ref={inputRef}
            autoComplete="off"
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            placeholder={
              (items || []).length > 0 ? "" : `Write the ${label.toLowerCase()}`
            }
          />
        </InputWrapper>
      </Container>
    </Wrapper>
  );
};

export default Keywords;
