import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  background-color: #24272a;
  padding: 4px 40px;
  box-shadow: rgba(40, 42, 49, 0.16) 0px 1px 2px 0px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.16);
  z-index: 4;
`;

const Header = styled(Link)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0px 12px;
  max-height: 100%;
`;

const Logo = styled.img`
  height: 64px;
  padding: 2px;
`;

const Menu = styled.div`
  position: absolute;
  display: ${(props) => (props.open ? "block" : "none")};
  top: 48px;
  border-radius: 8px;
  right: 24px;
  max-width: 320px;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%),
    0 2px 4px -1px rgb(0 0 0 / 20%);
  padding: 24px;
  transition: right 0.2s ease-out;
  z-index: 4;
`;

const AvatarWrapper = styled.div`
  cursor: pointer;
  margin-left: auto;
  margin-right: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }
`;

const MenuHeader = styled.div`
  display: flex;
  padding: 8px 0;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const Hello = styled.p`
  margin: 0 0 0 12px;
  font-weight: 600;
  text-transform: capitalize;
`;

const MenuTitle = styled.p`
  color: #011f3b;
  font-weight: 600;
  font-size: 14px;
  margin: 24px 0 12px 0;
  text-align: left;
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  color: ${(props) =>
    props.type === "logout" ? "rgb(247, 91, 29)" : "#011f3b"};
  margin-left: 12px;
  font-size: 13px;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    outline: none;
    background-color: transparent;
    box-shadow: none;
    text-decoration: underline;
  }
`;

const Item = styled.div`
  height: 48px;
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 400;
  color: ${(props) =>
    props.type === "logout" ? "rgb(247, 91, 29)" : "#011f3b"};
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    outline: none !important;
    background-color: transparent !important;
    color: #2b2929 !important;
    box-shadow: none !important;
  }
  margin-bottom: 12px;
`;

const IconWrapper = styled.div`
  padding: 8px 10px;
  display: flex;
  background-color: ${(props) => {
    switch (props.type) {
      case "logout":
        return "rgba(247, 91, 29, 0.16)";
      case "service":
        return "#EEF2FD";
      default:
        return "rgba(42, 172, 226, 0.16)";
    }
  }};
  border-radius: 8px;
`;

const Img = styled.div`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("${(props) => props.src}");
  border-radius: 100%;
`;

export {
  Wrapper,
  Menu,
  Item,
  StyledLink,
  Img,
  MenuHeader,
  Hello,
  Backdrop,
  AvatarWrapper,
  MenuTitle,
  IconWrapper,
  InnerWrapper,
  Header,
  Logo,
};
