import React, { useEffect } from "react";
import FormBuilder from "../../components/FormBuilder/FormBuilder";
import TranslationsPanel from "../../components/TranslationsPanel/TranslationsPanel";

const ProgramForm = ({ updateData, data, setState, documentId, action }) => {
  useEffect(() => {
    const getQuery = `
      query {
        program(_id: "${documentId}") {
          _id
          internalName,
          slug,
          active,
          startingPrice,
          keywords,
          itinerary {
            internalName,
            _id
          }
          bestTimeToVisit {
            _id,
            internalName
          }
          category {
            internalName,
            _id
          }
          location {
            internalName,
            _id
          }
          thumbnail {
            url,
            _id
          }
          translations {
             _id,
             title,
             subtitle,
             summary,
             language {
               _id,
               internalName
             }
          }
          signature {
            name,
            date
          }
        }
      }
    `;

    const writeQuery = `
      mutation write ($input: ProgramInput!) {
        ${action}Program (input: $input) {
          _id
          internalName,
          slug,
          active,
          startingPrice,
          keywords,
          itinerary {
            internalName,
            _id
          }
          bestTimeToVisit {
            _id,
            internalName
          }
          category {
            internalName,
            _id
          }
          thumbnail {
            url,
            _id
          }
          location {
            internalName,
            _id
          }
          translations {
             _id,
             title,
             subtitle,
             summary,
             language {
               _id,
               internalName
             }
          }
          signature {
            name,
            date
          }
        }
      }
    
    `;

    setState({ gqlQuery: { get: getQuery, write: writeQuery } });
  }, [setState, action, documentId]);

  const { translations = [], _id } = data;

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "active",
            type: "boolean",
            label: "Active",
            information:
              "Decide if the program is ready to be shown to public.",
            half: false,
          },
          {
            name: "internalName",
            type: "text",
            label: "Internal name",
            required: true,
            information:
              "This is a required field and it's only used for internal control.",
            half: true,
          },
          {
            name: "slug",
            type: "slug",
            label: "Slug",
            required: true,
            information: "This is a required field.",
            half: true,
          },
          {
            name: "location",
            type: "relation",
            collection: "locations",
            label: "Location",
            required: true,
            information: "This is a required field.",
            half: true,
          },
          {
            name: "category",
            collection: "categories",
            type: "relations",
            label: "Category",
            information: "This is a required field.",
            half: true,
          },
          {
            name: "itinerary",
            collection: "itineraries",
            type: "relation",
            label: "Itinerary",
            information: "This is a required field.",
            half: true,
          },
          {
            name: "bestTimeToVisit",
            collection: "periods",
            type: "relations",
            label: "Best time to visit",
            information: "This is a required field.",
            half: true,
          },
          {
            name: "startingPrice",
            type: "number",
            min: 0,
            step: "0.01",
            label: "Starting price (€)",
            information: "This is an optional field",
            half: true,
          },
          {
            name: "thumbnail",
            type: "image",
            label: "Thumbnail",
            information:
              "This is the image to be shown on small cards of the offers",
            half: true,
          },
          {
            name: "keywords",
            type: "keywords",
            label: "Keywords",
            information:
              'This is an optional field. Press "Enter" to insert the keyword',
            half: false,
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        translations={translations}
        updateData={updateData}
        setState={setState}
        parentId={_id}
        type="Program"
        fields={[
          {
            name: "language",
            type: "relation",
            collection: "languages",
            label: "Language",
            half: true,
            information: "This is a required field",
            required: true,
          },
          {
            name: "title",
            type: "text",
            label: "Program name",
            required: true,
            information:
              "This is a required field. Max number of characters: 31",
            half: true,
            maxLength: 31,
          },
          {
            name: "subtitle",
            type: "text",
            label: "Program subtitle",
            required: false,
            information:
              "If blank, this field will not be shown to public. Max number of characters: 45",
            half: true,
            maxLength: 45,
          },
          {
            name: "summary",
            type: "textarea",
            label: "Program summary",
            required: true,
            information:
              "This is a required field. Max number of characters: 225",
            half: true,
            maxLength: 225,
          },
        ]}
      />
    </>
  );
};

export default ProgramForm;
